import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
	HashRouter as Router,
	Switch,
	Route,
	Link,
	Redirect,
	useParams
} from "react-router-dom";
import {getId, setDialog, setSearch, getSearch} from '../utils/Redux';
import {MyInput, MySelect, MyTextArea, MyTr, query, formatRange, createOptions/*, getSearchQuery*/, storeSearch, emptySearch} from '../utils/utils';
import {danceData, regions, stateNames, states} from '../utils/data';
import { useSelector, useDispatch } from 'react-redux';
import SearchDlg from './SearchDlg';
import store from '../utils/store'
import SearchResult from './SearchResult';
import {MyTooltip} from '../utils/Tooltip';

export default function MainSearch(props) {
	let {id} = useParams()

	const [match, setMatch] = useState(true);
	const [time, setTime] = useState(0);
	const [results, setResults] = useState([]);
	const [profile, setProfile] = useState({});
	const [type, setType] = useState(0);
	const [order, setOrder] = useState(0);
	const [page, setPage] = useState(0);
	const folded = useState([false, false, false]);

	const dispatch = useDispatch();

	useEffect( () => {
		query('profile', [], data => {
			setProfile(data);
			var search = (id && data.searches.find(s => s.id==id)) || emptySearch;
			setType(search.typ);
			dispatch(setSearch(search));
		}, () => { // TODO:

		});
	},[])

	const limit = 10;
	function go(p, s=null) {
		let search = s ?? store.getState().search;
		const off = (p-1)*limit;
		//let {where, having} = getSearchQuery(search, match && profile);

		query('filterSearches', {
			data: JSON.stringify({search: search, match: match && profile}),
			limit: `${off}, ${limit+1}`,
			order: order
		}, data => {
			folded[1]([true, true, true])
			setPage(p)
			setResults(data)
		});
	}

	function saveSearch(overwrite) {
		let search = {...store.getState().search};
		if(!overwrite) delete search.id;
		storeSearch(search, id => {
			dispatch(setDialog(0));
		}, err => { // TODO
			console.log("ERROR", err);
		})
	}

	function save() {
		ReactDOM.render((
			<div style={{width:"100%", textAlign:"center"}}>
				{id && <button className="btn" style={{margin:"10px"}} onClick={e => saveSearch(true)}>Änderung speichern</button>}
				{
					profile.searches.length > 9
					? <button data-for="btn" data-tip="Du kannst maximal 10 Suchen speichern" className="btn disabled" style={{margin:"10px"}}>Neue Suche speichern</button>
					: <button className="btn" style={{margin:"10px"}} onClick={e => saveSearch(false)}>Neue Suche speichern</button>
				}
				<button className="btn" style={{margin:"10px"}} onClick={e => dispatch(setDialog(0))}>abbrechen</button>
				<MyTooltip id="btn" />
			</div>
		), document.getElementById("searchDlgFooter"));
		dispatch(setDialog(1));
	}

	var genders = {w:"weiblich", m:"männlich", d:"andere"}

	function changeType(type) {
		setType(type);
		if(type==1 && order==2) setOrder(0);
	}

	var resultsClone = [...results];
	var nextPage = results.length > limit;
	if(nextPage) resultsClone.pop();


	return (
		<div id="mainSearch">
			<SearchDlg show="2" folded={folded} setType={changeType}/>
			<div id="searchButtonRow">
				<button className="btn" style={{borderRadius:"10px 0px 0px 10px", display: "flex", alignItems:"center", justifyContent:"center"}} onClick={e => go(1)}>
					<img src="img/search.png" style={{height:"35px", marginRight:"30px"}}/>
					Suchen
				</button>
				<button className="btn" style={{borderRadius:"0px"}} onClick={e => save()}>Suche speichern</button>
				<div style={{borderRight:"1px solid #dfe9f5"}}>
					<span style={{marginRight:"20px"}}>Nur Gesuche, zu<br/> denen ich passe</span>
					<MyInput type="checkbox" checked={match} set={setMatch} />
				</div>
				<div style={{padding:"0px 10px"}}>
					Sortieren nach&nbsp;
					<MySelect set={setOrder}>
						<option value="0">Erstelldatum</option>
						<option value="1">Zuletzt Online</option>
						{type == 0 && <option value="2">Veranstaltungsdatum</option>}
					</MySelect>
				</div>
			</div>
			<div id="searchResults">
				{resultsClone.map((result,key) => (
					<Link key={result.sid} to={`/user/${result.uid}/${result.sid}`}><SearchResult typ={"search"} key={key} result={result}/></Link>
				))}
			</div>
			<div>
				{page > 1 && <button class="btn" style={{margin:"0px 10px"}} onClick={e => go(page-1)}>&lt;</button> }
				{nextPage && <button class="btn" style={{margin:"0px 10px"}} onClick={e => go(page+1)}>&gt;</button> }
			</div>
		</div>
	)
}
