import { createSlice } from '@reduxjs/toolkit';
import {query} from './utils'

const initial = {
	id: -1,
	name: "",
	showDlg: false,
	search: {
		typ: 0,
		genders: ['w', 'm', 'd'],
		dances: [[1,0,6]],
		regions:["Deutschland"]
	}
}

const slice = createSlice({
	name: 'user',
	initialState: initial,

	reducers: {
		reset: (state, action) => {
			state.id = -1
			state.name = -1
			state.search = initial.search
		},

		setId: (state, action) => {
			state.id = action.payload.id;
		},

		setDialog: (state, action) => {
			state.showDlg = action.payload;
		},

		setSearch: (state, action) => {
			state.search = action.payload;
		}

	},
});

export const getId = state => state.id;
export const getSearch = state => state.search;
export const showDlg = state => state.showDlg;
export const {reset, setId, setDialog, setSearch} = slice.actions;



export default slice.reducer;
