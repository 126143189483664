import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

export default function Terms() {
	// todo
	return (
		<div id="agb" style={{alignItems:"flex-start", padding:"20px"}}>
			<h1>Nutzungsbedingungen</h1>
			<div>
				<p>Der Anbieter dieser Seite ist nicht für die Inhalte der hier verfassten Texte verantwortlich.</p>

				<p>Sie bestätigen mit Ihrer Anmeldung, dass Sie die angebotenen Services dieser Seite nicht zur Verbreitung von Inhalten verwenden, die Diffamierungen, Beleidigungen, Hass, Bedrohungen, Spam, Aufrufe zu Straftaten, Verletzungen des Jugendschutzes oder von Urheberrechten enthalten oder anderweitig strafrechtlich relevant sind.</p>

				<p>Verboten sind auch:</p>
				<ul>
					<li>Gesuche oder Angebote von Tieren</li>
					<li>Gesuche oder Angebote von Waffen</li>
					<li>Parteiwerbung</li>
					<li>Spendenaufrufe, außer wenn mit dem Team abgesprochen</li>
					<li>Aufforderungen zur Teilnahme an Petitionen (erlaubt sind ausschließlich Petitionen rund um Garten und Umweltschutz)</li>
					<li>Aufrufe zur Teilnahme an externen Gewinnspielen</li>
				</ul>
				<p></p>

				<p>Jegliche Inhalte, egal ob erstellt oder hochgeladen, können von unserem Team überprüft werden. Erstellen Sie keine Inhalte oder Informationen, bei denen Sie Bedenken haben, dass sie privat oder vertraulich sind.</p>

				<p>Wir behalten uns das Recht vor, jeglichen Beitrag, ohne Angabe von Gründen, zu bearbeiten oder zu löschen. Es liegt in unserer Entscheidung, ob wir Bitten um die Bearbeitung oder Löschung von Beiträgen nachkommen. Wir behalten uns ebenfalls das Recht vor, bei Vorliegen von Verstößen Maßnahmen gegen Benutzerkonten durchzuführen.</p>

				<p>
					Sie erteien uns ein nicht exklusives, permanentes, unwiderrufliches, und unbegrenztes Nutzungsrecht für die Inhalte, die Sie im Zusammenhang mit Ihrer Seite erstellen.
					Dies beinhaltet sowohl die Veröffentlichung als auch eine Neu-Veröffentlichung. Du behältst dabei das vollständige Urheberrecht an deinen Inhalten.
				</p>

				<p>Wir behalten uns vor, die Nutzungsbedingungen zu einem späteren Zeitpunkt anzupassen.</p>
			</div>
			<h1>Datenschutzerklärung</h1>
			<h2 id="m1870">Einleitung</h2>
			<p>
				Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als "Daten“ bezeichnet)
				wir zu welchen Zwecken und in welchem Umfang im Rahmen der Bereitstellung unserer Applikation verarbeiten.
			</p>
			<p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
			<p>Stand: 16. Mai 2022</p>
			<h2>Inhaltsübersicht</h2>
			<ul class="index">
				<li>Einleitung</li>
				<li>Verantwortlicher</li>
				<li>Übersicht der Verarbeitungen</li>
				<li>Löschung von Daten</li>
				<li>Bereitstellung des Onlineangebotes und Webhosting</li>
				<li>Registrierung, Anmeldung und Nutzerkonto</li>
				<li>Kontakt- und Anfragenverwaltung</li>
			</ul>
			<h2 id="m3">Verantwortlicher</h2>
			<p>Jens Stuber<br/>Trollingerweg 12<br/>70734 Fellbach</p>
			E-Mail-Adresse: <p><a href="mailto:tanzpartnerwanted@googlemail.com">tanzpartnerwanted@googlemail.com</a></p>
			<h2 id="mOverview">Übersicht der Verarbeitungen</h2>
			<p>Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen Personen.</p>
			<h3>Arten der verarbeiteten Daten</h3>
			<ul>
				<li>Bestandsdaten.</li>
				<li>Kontaktdaten.</li>
				<li>Inhaltsdaten.</li>
				<li>Nutzungsdaten.</li>
				<li>Meta-/Kommunikationsdaten.</li>
			</ul>
			<h3>Zwecke der Verarbeitung</h3>
			<ul>
				<li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
				<li>Kontaktanfragen und Kommunikation.</li>
				<li>Sicherheitsmaßnahmen.</li>
				<li>Verwaltung und Beantwortung von Anfragen.</li>
			</ul>
			<h2 id="m12">Löschung von Daten</h2>
			<p>
				Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten Einwilligungen widerrufen werden
				oder sonstige Erlaubnisse entfallen (z.B. wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht erforderlich sind).
			</p>
			<p>
				Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung auf diese Zwecke beschränkt.
				D.h., die Daten werden gesperrt und nicht für andere Zwecke verarbeitet.
				Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
				oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person erforderlich ist.
			</p>
			<p>Unsere Datenschutzhinweise können ferner weitere Angaben zu der Aufbewahrung und Löschung von Daten beinhalten, die für die jeweiligen Verarbeitungen vorrangig gelten.</p>
			<h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
			<p>
				Um unser Onlineangebot sicher und effizient bereitstellen zu können, nehmen wir die Leistungen von einem Webhosting-Anbietern in Anspruch,
				von deren Servern (bzw. von ihnen verwalteten Servern) das Onlineangebot abgerufen werden kann.
				Zu diesen Zwecken können wir Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste sowie Sicherheitsleistungen und technische Wartungsleistungen in Anspruch nehmen.
			</p>
			<p>
				Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten Daten können alle die Nutzer unseres Onlineangebotes betreffenden Angaben gehören, die im Rahmen der Nutzung und der Kommunikation anfallen.
				Hierzu gehören regelmäßig die IP-Adresse, die notwendig ist, um die Inhalte von Onlineangeboten an Browser ausliefern zu können, und alle innerhalb unseres Onlineangebotes oder von Webseiten getätigten Eingaben.
			</p>
			<ul class="m-elements">
				<li>
					<strong>Verarbeitete Datenarten:</strong>
					Inhaltsdaten (z.B. Eingaben in Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
				</li>
				<li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
				<li><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit; Erbringung vertraglicher Leistungen und Kundenservice.</li>
				<li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).</li>
			</ul>
			<p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
			<ul class="m-elements">
				<li>
					<strong>Erhebung von Zugriffsdaten und Logfiles: </strong>
					Wir selbst (bzw. unser Webhostinganbieter) erheben Daten zu jedem Zugriff auf den Server (sogenannte Serverlogfiles).
					Zu den Serverlogfiles können die Adresse und Name der abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp nebst Version,
					das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite) und im Regelfall IP-Adressen und der anfragende Provider gehören.
					Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt werden, z.B., um eine Überlastung der Server zu vermeiden (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken)
					und zum anderen, um die Auslastung der Server und ihre Stabilität sicherzustellen;
					<strong>Löschung von Daten:</strong> Logfile-Informationen werden für die Dauer von maximal 30 Tagen gespeichert und danach gelöscht oder anonymisiert.
					Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der Löschung ausgenommen.
				</li>
				<li>
					<strong>IONOS by 1&1: </strong>Leistungen auf dem Gebiet der Bereitstellung von informationstechnischer Infrastruktur und verbundenen Dienstleistungen (z.B. Speicherplatz und/oder Rechenkapazitäten);
					<strong>Dienstanbieter:</strong> 1&1 IONOS SE, Elgendorfer Str. 57, 56410 Montabaur, Deutschland;
					<strong>Website:</strong> <a href="https://www.ionos.de" target="_blank">https://www.ionos.de</a>;
					<strong>Datenschutzerklärung:</strong> <a href="https://www.ionos.de/terms-gtc/terms-privacy" target="_blank">https://www.ionos.de/terms-gtc/terms-privacy</a>;
					<strong><a href="https://drive.google.com/file/d/171S3GvlcQpLXQXQzVk8wqnecPHDQqnwR/view?usp=sharing">Auftragsverarbeitungsvertrag</a></strong>
				</li>
			</ul>
			<h2 id="m367">Registrierung, Anmeldung und Nutzerkonto</h2>
			<p>
				Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung werden den Nutzern die erforderlichen Pflichtangaben mitgeteilt und zu Zwecken der Bereitstellung des Nutzerkontos auf Grundlage
				vertraglicher Pflichterfüllung verarbeitet. Zu den verarbeiteten Daten gehören insbesondere die Login-Informationen (Nutzername, Passwort sowie eine E-Mail-Adresse).
			</p>
			<p>
				Im Rahmen der Inanspruchnahme unserer Registrierungs- und Anmeldefunktionen sowie der Nutzung des Nutzerkontos speichern wir die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung.
				Die Speicherung erfolgt auf Grundlage unserer berechtigten Interessen als auch jener der Nutzer an einem Schutz vor Missbrauch und sonstiger unbefugter Nutzung.
				Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, es sei denn, sie ist zur Verfolgung unserer Ansprüche erforderlich oder es besteht eine gesetzliche Verpflichtung hierzu.
			</p>
			<p>Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant sind, wie z.B. technische Änderungen, per E-Mail informiert werden.</p>
			<ul class="m-elements">
				<li>
					<strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern);
					Inhaltsdaten (z.B. Eingaben in Onlineformularen); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
				</li>
				<li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
				<li><strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Kundenservice; Sicherheitsmaßnahmen; Verwaltung und Beantwortung von Anfragen.</li>
				<li><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).</li>
			</ul>
			<p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
			<ul class="m-elements">
				<li><strong>Registrierung mit Pseudonymen: </strong>Nutzer dürfen statt Klarnamen Pseudonyme als Nutzernamen verwenden.</li>
				<li><strong>Profile der Nutzer sind öffentlich: </strong>Die Profile der Nutzer sind öffentlich sichtbar und zugänglich.</li>
				<li><strong>Löschung von Daten nach Kündigung: </strong>Wenn Nutzer ihr Nutzerkonto gekündigt haben, werden deren Daten im Hinblick auf das Nutzerkonto, vorbehaltlich einer gesetzlichen Erlaubnis, Pflicht oder Einwilligung der Nutzer, gelöscht.</li>
				<li>
					<strong>Keine Aufbewahrungspflicht für Daten: </strong>
					Es obliegt den Nutzern, ihre Daten bei erfolgter Kündigung vor dem Vertragsende zu sichern. Wir sind berechtigt, sämtliche während der Vertragsdauer gespeicherte Daten des Nutzers unwiederbringlich zu löschen.
				</li>
			</ul>
			<h2 id="m182">Kontakt- und Anfragenverwaltung</h2>
			<p>
				Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via soziale Medien) sowie im Rahmen bestehender Nutzer- und Geschäftsbeziehungen werden die Angaben der anfragenden Personen verarbeitet
				soweit dies zur Beantwortung der Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich ist.
			</p>
			<p>
				Die Beantwortung der Kontaktanfragen sowie die Verwaltung von Kontakt- und Anfragedaten im Rahmen von vertraglichen oder vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer vertraglichen Pflichten oder zur
				Beantwortung von (vor)vertraglichen Anfragen und im Übrigen auf Grundlage der berechtigten Interessen an der Beantwortung der Anfragen und Pflege von Nutzer- bzw. Geschäftsbeziehungen.
			</p>
			<ul class="m-elements">
				<li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen).</li>
				<li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
				<li><strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation; Erbringung vertraglicher Leistungen und Kundenservice.</li>
				<li>
					<strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO);
					Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO).
				</li>
			</ul>
			<p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
			<ul class="m-elements">
				<li>
					<strong>Kontaktformular: </strong>
					Wenn Nutzer über unser Kontaktformular, E-Mail oder andere Kommunikationswege mit uns in Kontakt treten, verarbeiten wir die uns in diesem Zusammenhang mitgeteilten Daten zur Bearbeitung des mitgeteilten Anliegens.
					Zu diesem Zweck verarbeiten wir personenbezogene Daten im Rahmen vorvertraglicher und vertraglicher Geschäftsbeziehungen, soweit dies zu deren Erfüllung erforderlich ist
					und im Übrigen auf Grundlage unserer berechtigten Interessen sowie der Interessen der Kommunikationspartner an der Beantwortung der Anliegen und unserer gesetzlichen Aufbewahrungspflichten.
				</li>
			</ul>
			<p class="seal">
				<a href="https://datenschutz-generator.de/" title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken." target="_blank" rel="noopener noreferrer nofollow">
					<img src="https://datenschutz-generator.de/wp-content/plugins/ts-dsg/images/dsg-seal/dsg-seal-pp-de.png" alt="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken." width="250" height="250"/>
				</a>
			</p>
		</div>
	)
}
