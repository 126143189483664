import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {useParams, Link} from 'react-router-dom';
import axios from 'axios';
import {getId, setDialog, setSearch, getSearch} from '../utils/Redux';
import {MyInput, MySelect, MyTextArea, MyTr, query, formatRange, createOptions, serverUrl, formatDate} from '../utils/utils';
import {danceData, regions, stateNames, states} from '../utils/data';
import { useSelector, useDispatch } from 'react-redux';
import SearchDlg from './SearchDlg';
import SearchResult from './SearchResult'
import store from '../utils/store'

export default function User(props) {
	let {id, sid} = useParams()
	const [state, setState] = useState({user: null, skills: null, searches:null});
	const [dim, setDim] = useState([300,400])
	useEffect( () => {
		if(id == undefined) return;
		query('profile', {id:id}, data => {
			setState(data);
			const img = new Image()
			img.onload = function() {
				var w = 300
				var h = Math.floor(this.height * 300/this.width)
				if(h>400) {
					h = 400
					var w = Math.floor(this.width * 400/this.height)
				}
				setDim([w, h])
			}
			img.src = "https://tanzpartnerwanted.2ix.de/img/user/" + data.user.img
		})
	},[])



	if(state.user == null) return null;
	const searches = [...state.searches];
	if(sid != undefined) {
		var idx = searches.findIndex(s => s.id == sid);
		if(idx >= 0) searches.splice(0,0, searches.splice(idx,1)[0]);
	}
	let user = state.user;
	var genders = {w:"weiblich", m:"männlich", d:"andere"}
	return (
		<div id="user">
		<br/>
			<button className="btn" style={{borderRadius:"10px", display:"flex", alignItems:"center", justifyContent:"space-evenly"}} onClick={e => props.history.push(`/messages/${user.id}`)}>
				<img style={{height:"25px", margin:"10px"}} src="img/msg.png"/>
				<div style={{display:"inline-block", textAlign:"left", margin:"10px"}}>Nachricht<br/>senden</div>
			</button>
			<div className="flexContainer" style={{alignItems:"flex-start", width:"1200px", justifyContent:"space-between"}}>
				{ user.img.startsWith('user') &&
				<img style={{width:dim[0]+"px", height:dim[1]+"px", alignSelf:"center"}} src={"img/user/" + user.img}/>
				}
				<div id="userInfo" className="container" style={{width:"270px"}}>
					<h2><span>{user.name}</span></h2>
					<div className="filler">
						<p><b>Alter</b>: {user.age ?? "Keine Angabe"} </p>
						<p><b>Größe</b>: {user.size ?? "Keine Angabe"} </p>
						<p><b>Geschlecht</b>: {user.gender ? genders[user.gender] :  "Keine Angabe"} </p>
						<p><b>Postleitzahl</b>: {user.plz ?? "Keine Angabe"} </p>
						<p><b>Zuletzt online</b>: {formatDate(user.lastOnline)} </p>
					</div>
				</div>
				<div id="userSkills" className="container" style={{width:"270px"}}>
					<h2 style={{marginTop:"0px", textAlign: "center"}}><span>Kenntnisse</span></h2>
					<div className="filler">
						{ Object.values(state.skills).map(skill => {var dance = danceData[skill.dance-1]; return (
							<p key={skill.dance}><b>{dance.name}</b>: {dance.levels[skill.level]} </p>
						)})}
					</div>
				</div>
				{user.signature &&
					<div id="signature" className="container" style={{width:"270px"}}>
						<h2><span>Über mich</span></h2>
						<div className="filler">
							<br/><xmp>{user.signature}</xmp>
						</div>
					</div>}
			</div><br/>
			<h2>Gesuche</h2>
			<div id="searchResults">
			{searches.map(search => (<SearchResult typ={"user"} marked={search.id == sid} key={search.id} result={{...search,
				age: formatRange(search.minAge, search.maxAge) ?? "egal",
				size: formatRange(search.minSize, search.maxSize, "cm") ?? "egal",
				gender: search.genders.join("/")
			}}/>))}
			</div>
		</div>
	)
}
