import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

export default function HowTo() {
	return (
		<div style={{alignItems:"start"}}>
			<div className="container" style={{margin:"30px"}}>
				<h2><span>Wie diese Seite funktioniert</span></h2>
				<div className="filler">
				<p>Tanzpartner Wanted eignet sich sowohl für die Suche nach einem/einer Tanzpartner/in für Turniertanz, Tanzkurs, o.Ä. als auch für einzelne Termine, z.B. für Tanzparty, Ball, etc.</p>
				<p>Wie Sie die Seite dafür optimal nutzen, erfahren Sie hier:</p>
				<p>
					In seinem Profil kann man Suchen anlegen, diese können öffentlich sein oder privat.<br/>
					In der Hauptsuche sucht man dann genau genommen nicht nach Mitgliedern, sondern nach diesen (öffentlichen) Gesuchen.<br/>
					Man kann die gespeicherten Suchen auch benutzen um direkt die Hauptsuche zu befüllen und nach passenden Gesuchen anderer Mitglieder suchen.
				</p>
				<Expandable title="Was ist ein 'passendes' Gesuch?">
					Wenn auf dieser Seite von zueinander passenden Gesuchen die Rede ist, dann sind damit 2 Gesuche gemeint, für die gilt:
					<ul>
						<li>Die Mitglieder erfüllen die allgemeinen Suchkriterien(Alter, Größe, Geschlecht) des jeweils anderen.<br/>
						(Wenn man eine Information nicht angegeben hat, kann erfüllt man nur Gesuche, die dafür kein Suchkriterium angegeben haben)</li>
						<li>Die beiden Gesuche haben mindestens einen gemeinsamen Tanz, bei dem jeder die min/max-level Angaben des anderen Gesuchs erfüllt<br/>
						(Wenn man für einen Tanz keine eigenen Kenntnisse angegeben hat, zählt das als "keine Vorkenntnisse")</li>
						<li>Die beiden Gesuche haben mindestens ein gemeinsames Gebiet.</li>
					</ul>
				</Expandable>
				<Expandable title="Beispiel: Ich möchte, dass potenzielle Tanzpartner mich in der Hauptsuche finden können">
					Dazu erstellt man eine Suche (in seinem Profil), bei welcher das Feld "öffentlich" aktiviert ist.<br/>
					Wenn man möchte kann man auch das Feld "Benachrichtigungen" aktivieren, um per mail benachrichtigt zu werden, wann immer jemand ein passendes, öffentliches Gesuch erstellt
				</Expandable>
				<Expandable title="Beispiel: Ich möchte immer benachrichtigt werden, wenn jemand (der/die bestimmte Suchkriterien erfüllt) jemanden zum tanzen gehen sucht ohne dafür ein öffentliches Gesuch zu erstellen.">
					Dazu erstellt man ein Gesuch, gibt als Typ "Veranstaltung" an, setzt aber kein Datum, deaktivier "öffentlich" und aktiviert Benachrichtigungen für dieses Gesuch.<br/>
					Man wird nur benachrichtigt, wenn jemand ein passendes öffentliches Gesuch erstellt.
				</Expandable>
				</div>
			</div>
		</div>
	)
}

// <Expandable title="">
//
// </Expandable>

function Expandable(props) {
	const [visible, setVisible] = useState(false);
	var style={display:"inline-block"}
	if(visible) style.transform="rotate(90deg)";
	return (
		<div>
			<h2 style={{height:"unset"}}><span style={{fontSize:"20px"}}>
				<a style={{cursor:'pointer'}} onClick={() => setVisible(!visible)}>{props.title}</a>
			</span></h2>
			{visible && props.children}
			<br/>
		</div>
	)

}
//<p style={style}>&#9654;</p>&nbsp;
//<b><u>{props.title}</u></b>
