export const danceData = [
	{id: 1, name: 'Standard/Latein',levels : ['keine Vorkentnisse','Anfänger','Fortgeschritten','Bronze','Silber','Gold','Tanzkreis']},
	{id: 2, name: 'Salsa',levels : ['keine Vorkentnisse','Anfänger','Fortgeschritten','Experte']},
	{id: 3, name: 'Turniertanz Standard',levels : ['keine Vorkentnisse','D-Klasse','C-Klasse','B-Klasse','A-Klasse','S-Klasse']},
	{id: 4, name: 'Turniertanz Latein',levels : ['keine Vorkentnisse','D-Klasse','C-Klasse','B-Klasse','A-Klasse','S-Klasse']},
	{id: 5, name: 'West Coast Swing',levels : ['keine Vorkenntnisse', 'Novice', 'Intermediate', 'Advanced', 'Allstars', 'Champion']}
];

export const regions = [
	{region:'LK Aachen', state:'Nordrhein-Westfalen'},
	{region:'LK Ahrweiler', state:'Rheinland-Pfalz'},
	{region:'LK Aichach-Friedberg', state:'Bayern'},
	{region:'LK Alb-Donau-Kreis', state:'Baden-Württemberg'},
	{region:'LK Altenburger Land', state:'Thüringen'},
	{region:'LK Altenkirchen', state:'Rheinland-Pfalz'},
	{region:'LK Altmarkkreis Salzwedel', state:'Sachsen-Anhalt'},
	{region:'LK Altötting', state:'Bayern'},
	{region:'LK Alzey-Worms', state:'Rheinland-Pfalz'},
	{region:'LK Amberg-Sulzbach', state:'Bayern'},
	{region:'LK Ammerland', state:'Niedersachsen'},
	{region:'LK Anhalt-Bitterfeld', state:'Sachsen-Anhalt'},
	{region:'LK Ansbach', state:'Bayern'},
	{region:'LK Aschaffenburg', state:'Bayern'},
	{region:'LK Augsburg', state:'Bayern'},
	{region:'LK Aurich', state:'Niedersachsen'},
	{region:'LK Bad Dürkheim', state:'Rheinland-Pfalz'},
	{region:'LK Bad Kissingen', state:'Bayern'},
	{region:'LK Bad Kreuznach', state:'Rheinland-Pfalz'},
	{region:'LK Bad Tölz-Wolfratshausen', state:'Bayern'},
	{region:'LK Bamberg', state:'Bayern'},
	{region:'LK Barnim', state:'Brandenburg'},
	{region:'LK Bautzen', state:'Sachsen'},
	{region:'LK Bayreuth', state:'Bayern'},
	{region:'LK Berchtesgadener Land', state:'Bayern'},
	{region:'LK Bergstraße', state:'Hessen'},
	{region:'LK Bernkastel-Wittlich', state:'Rheinland-Pfalz'},
	{region:'LK Biberach', state:'Baden-Württemberg'},
	{region:'LK Birkenfeld', state:'Rheinland-Pfalz'},
	{region:'LK Böblingen', state:'Baden-Württemberg'},
	{region:'LK Bodenseekreis', state:'Baden-Württemberg'},
	{region:'LK Börde', state:'Sachsen-Anhalt'},
	{region:'LK Borken', state:'Nordrhein-Westfalen'},
	{region:'LK Breisgau-Hochschwarzwald', state:'Baden-Württemberg'},
	{region:'LK Burgenlandkreis', state:'Sachsen-Anhalt'},
	{region:'LK Calw', state:'Baden-Württemberg'},
	{region:'LK Celle', state:'Niedersachsen'},
	{region:'LK Cham', state:'Bayern'},
	{region:'LK Cloppenburg', state:'Niedersachsen'},
	{region:'LK Coburg', state:'Bayern'},
	{region:'LK Cochem-Zell', state:'Rheinland-Pfalz'},
	{region:'LK Coesfeld', state:'Nordrhein-Westfalen'},
	{region:'LK Cuxhaven', state:'Niedersachsen'},
	{region:'LK Dachau', state:'Bayern'},
	{region:'LK Dahme-Spreewald', state:'Brandenburg'},
	{region:'LK Darmstadt-Dieburg', state:'Hessen'},
	{region:'LK Deggendorf', state:'Bayern'},
	{region:'LK Diepholz', state:'Niedersachsen'},
	{region:'LK Dillingen an der Donau', state:'Bayern'},
	{region:'LK Dingolfing-Landau', state:'Bayern'},
	{region:'LK Dithmarschen', state:'Schleswig-Holstein'},
	{region:'LK Donau-Ries', state:'Bayern'},
	{region:'LK Donnersbergkreis', state:'Rheinland-Pfalz'},
	{region:'LK Düren', state:'Nordrhein-Westfalen'},
	{region:'LK Ebersberg', state:'Bayern'},
	{region:'LK Eichsfeld', state:'Thüringen'},
	{region:'LK Eichstätt', state:'Bayern'},
	{region:'LK Eifelkreis Bitburg-Prüm', state:'Rheinland-Pfalz'},
	{region:'LK Elbe-Elster', state:'Brandenburg'},
	{region:'LK Emmendingen', state:'Baden-Württemberg'},
	{region:'LK Emsland', state:'Niedersachsen'},
	{region:'LK Ennepe-Ruhr-Kreis', state:'Nordrhein-Westfalen'},
	{region:'LK Enzkreis', state:'Baden-Württemberg'},
	{region:'LK Erding', state:'Bayern'},
	{region:'LK Erlangen-Höchstadt', state:'Bayern'},
	{region:'LK Erzgebirgskreis', state:'Sachsen'},
	{region:'LK Esslingen', state:'Baden-Württemberg'},
	{region:'LK Euskirchen', state:'Nordrhein-Westfalen'},
	{region:'LK Forchheim', state:'Bayern'},
	{region:'LK Freising', state:'Bayern'},
	{region:'LK Freudenstadt', state:'Baden-Württemberg'},
	{region:'LK Freyung-Grafenau', state:'Bayern'},
	{region:'LK Friesland', state:'Niedersachsen'},
	{region:'LK Fulda', state:'Hessen'},
	{region:'LK Fürstenfeldbruck', state:'Bayern'},
	{region:'LK Fürth', state:'Bayern'},
	{region:'LK Garmisch-Partenkirchen', state:'Bayern'},
	{region:'LK Germersheim', state:'Rheinland-Pfalz'},
	{region:'LK Gießen', state:'Hessen'},
	{region:'LK Gifhorn', state:'Niedersachsen'},
	{region:'LK Göppingen', state:'Baden-Württemberg'},
	{region:'LK Görlitz', state:'Sachsen'},
	{region:'LK Goslar', state:'Niedersachsen'},
	{region:'LK Gotha', state:'Thüringen'},
	{region:'LK Göttingen', state:'Niedersachsen'},
	{region:'LK Grafschaft Bentheim', state:'Niedersachsen'},
	{region:'LK Greiz', state:'Thüringen'},
	{region:'LK Groß-Gerau', state:'Hessen'},
	{region:'LK Günzburg', state:'Bayern'},
	{region:'LK Gütersloh', state:'Nordrhein-Westfalen'},
	{region:'LK Hameln-Pyrmont', state:'Niedersachsen'},
	{region:'LK Hannover', state:'Niedersachsen'},
	{region:'LK Harburg', state:'Niedersachsen'},
	{region:'LK Harz', state:'Sachsen-Anhalt'},
	{region:'LK Haßberge', state:'Bayern'},
	{region:'LK Havelland', state:'Brandenburg'},
	{region:'LK Heidekreis', state:'Niedersachsen'},
	{region:'LK Heidenheim', state:'Baden-Württemberg'},
	{region:'LK Heilbronn', state:'Baden-Württemberg'},
	{region:'LK Heinsberg', state:'Nordrhein-Westfalen'},
	{region:'LK Helmstedt', state:'Niedersachsen'},
	{region:'LK Herford', state:'Nordrhein-Westfalen'},
	{region:'LK Hersfeld-Rotenburg', state:'Hessen'},
	{region:'LK Herzogtum Lauenburg', state:'Schleswig-Holstein'},
	{region:'LK Hildburghausen', state:'Thüringen'},
	{region:'LK Hildesheim', state:'Niedersachsen'},
	{region:'LK Hochsauerlandkreis', state:'Nordrhein-Westfalen'},
	{region:'LK Hochtaunuskreis', state:'Hessen'},
	{region:'LK Hof', state:'Bayern'},
	{region:'LK Hohenlohekreis', state:'Baden-Württemberg'},
	{region:'LK Holzminden', state:'Niedersachsen'},
	{region:'LK Höxter', state:'Nordrhein-Westfalen'},
	{region:'LK Ilm-Kreis', state:'Thüringen'},
	{region:'LK Jerichower Land', state:'Sachsen-Anhalt'},
	{region:'LK Kaiserslautern', state:'Rheinland-Pfalz'},
	{region:'LK Karlsruhe', state:'Baden-Württemberg'},
	{region:'LK Kassel', state:'Hessen'},
	{region:'LK Kelheim', state:'Bayern'},
	{region:'LK Kitzingen', state:'Bayern'},
	{region:'LK Kleve', state:'Nordrhein-Westfalen'},
	{region:'LK Konstanz', state:'Baden-Württemberg'},
	{region:'LK Kronach', state:'Bayern'},
	{region:'LK Kulmbach', state:'Bayern'},
	{region:'LK Kusel', state:'Rheinland-Pfalz'},
	{region:'LK Kyffhäuserkreis', state:'Thüringen'},
	{region:'LK Lahn-Dill-Kreis', state:'Hessen'},
	{region:'LK Landsberg am Lech', state:'Bayern'},
	{region:'LK Landshut', state:'Bayern'},
	{region:'LK Leer', state:'Niedersachsen'},
	{region:'LK Leipzig', state:'Sachsen'},
	{region:'LK Lichtenfels', state:'Bayern'},
	{region:'LK Limburg-Weilburg', state:'Hessen'},
	{region:'LK Lindau (Bodensee)', state:'Bayern'},
	{region:'LK Lippe', state:'Nordrhein-Westfalen'},
	{region:'LK Lörrach', state:'Baden-Württemberg'},
	{region:'LK Lüchow-Dannenberg', state:'Niedersachsen'},
	{region:'LK Ludwigsburg', state:'Baden-Württemberg'},
	{region:'LK Ludwigslust-Parchim', state:'Mecklenburg-Vorpommern'},
	{region:'LK Lüneburg', state:'Niedersachsen'},
	{region:'LK Main-Kinzig-Kreis', state:'Hessen'},
	{region:'LK Main-Spessart', state:'Bayern'},
	{region:'LK Main-Tauber-Kreis', state:'Baden-Württemberg'},
	{region:'LK Main-Taunus-Kreis', state:'Hessen'},
	{region:'LK Mainz-Bingen', state:'Rheinland-Pfalz'},
	{region:'LK Mansfeld-Südharz', state:'Sachsen-Anhalt'},
	{region:'LK Marburg-Biedenkopf', state:'Hessen'},
	{region:'LK Märkischer Kreis', state:'Nordrhein-Westfalen'},
	{region:'LK Märkisch-Oderland', state:'Brandenburg'},
	{region:'LK Mayen-Koblenz', state:'Rheinland-Pfalz'},
	{region:'LK Mecklenburgische Seenplatte', state:'Mecklenburg-Vorpommern'},
	{region:'LK Meißen', state:'Sachsen'},
	{region:'LK Merzig-Wadern', state:'Saarland'},
	{region:'LK Mettmann', state:'Nordrhein-Westfalen'},
	{region:'LK Miesbach', state:'Bayern'},
	{region:'LK Miltenberg', state:'Bayern'},
	{region:'LK Minden-Lübbecke', state:'Nordrhein-Westfalen'},
	{region:'LK Mittelsachsen', state:'Sachsen'},
	{region:'LK Mühldorf am Inn', state:'Bayern'},
	{region:'LK München', state:'Bayern'},
	{region:'LK Neckar-Odenwald-Kreis', state:'Baden-Württemberg'},
	{region:'LK Neu-Ulm', state:'Bayern'},
	{region:'LK Neuburg-Schrobenhausen', state:'Bayern'},
	{region:'LK Neumarkt in der Oberpfalz', state:'Bayern'},
	{region:'LK Neunkirchen', state:'Saarland'},
	{region:'LK Neustadt an der Aisch-Bad Windsheim', state:'Bayern'},
	{region:'LK Neustadt an der Waldnaab', state:'Bayern'},
	{region:'LK Neuwied', state:'Rheinland-Pfalz'},
	{region:'LK Nienburg/Weser', state:'Niedersachsen'},
	{region:'LK Nordfriesland', state:'Schleswig-Holstein'},
	{region:'LK Nordhausen', state:'Thüringen'},
	{region:'LK Nordsachsen', state:'Sachsen'},
	{region:'LK Nordwestmecklenburg', state:'Mecklenburg-Vorpommern'},
	{region:'LK Northeim', state:'Niedersachsen'},
	{region:'LK Nürnberger Land', state:'Bayern'},
	{region:'LK Oberallgäu', state:'Bayern'},
	{region:'LK Oberbergischer Kreis', state:'Nordrhein-Westfalen'},
	{region:'LK Oberhavel', state:'Brandenburg'},
	{region:'LK Oberspreewald-Lausitz', state:'Brandenburg'},
	{region:'LK Odenwaldkreis', state:'Hessen'},
	{region:'LK Oder-Spree', state:'Brandenburg'},
	{region:'LK Offenbach', state:'Hessen'},
	{region:'LK Oldenburg', state:'Niedersachsen'},
	{region:'LK Olpe', state:'Nordrhein-Westfalen'},
	{region:'LK Ortenaukreis', state:'Baden-Württemberg'},
	{region:'LK Osnabrück', state:'Niedersachsen'},
	{region:'LK Ostalbkreis', state:'Baden-Württemberg'},
	{region:'LK Ostallgäu', state:'Bayern'},
	{region:'LK Osterholz', state:'Niedersachsen'},
	{region:'LK Ostholstein', state:'Schleswig-Holstein'},
	{region:'LK Ostprignitz-Ruppin', state:'Brandenburg'},
	{region:'LK Paderborn', state:'Nordrhein-Westfalen'},
	{region:'LK Passau', state:'Bayern'},
	{region:'LK Peine', state:'Niedersachsen'},
	{region:'LK Pfaffenhofen an der Ilm', state:'Bayern'},
	{region:'LK Pinneberg', state:'Schleswig-Holstein'},
	{region:'LK Plön', state:'Schleswig-Holstein'},
	{region:'LK Potsdam-Mittelmark', state:'Brandenburg'},
	{region:'LK Prignitz', state:'Brandenburg'},
	{region:'LK Rastatt', state:'Baden-Württemberg'},
	{region:'LK Ravensburg', state:'Baden-Württemberg'},
	{region:'LK Recklinghausen', state:'Nordrhein-Westfalen'},
	{region:'LK Regen', state:'Bayern'},
	{region:'LK Regensburg', state:'Bayern'},
	{region:'LK Rems-Murr-Kreis', state:'Baden-Württemberg'},
	{region:'LK Rendsburg-Eckernförde', state:'Schleswig-Holstein'},
	{region:'LK Reutlingen', state:'Baden-Württemberg'},
	{region:'LK Rhein-Erft-Kreis', state:'Nordrhein-Westfalen'},
	{region:'LK Rheingau-Taunus-Kreis', state:'Hessen'},
	{region:'LK Rhein-Hunsrück-Kreis', state:'Rheinland-Pfalz'},
	{region:'LK Rheinisch-Bergischer Kreis', state:'Nordrhein-Westfalen'},
	{region:'LK Rhein-Kreis Neuss', state:'Nordrhein-Westfalen'},
	{region:'LK Rhein-Lahn-Kreis', state:'Rheinland-Pfalz'},
	{region:'LK Rhein-Neckar-Kreis', state:'Baden-Württemberg'},
	{region:'LK Rhein-Pfalz-Kreis', state:'Rheinland-Pfalz'},
	{region:'LK Rhein-Sieg-Kreis', state:'Nordrhein-Westfalen'},
	{region:'LK Rhön-Grabfeld', state:'Bayern'},
	{region:'LK Rosenheim', state:'Bayern'},
	{region:'LK Rostock', state:'Mecklenburg-Vorpommern'},
	{region:'LK Rotenburg (Wümme)', state:'Niedersachsen'},
	{region:'LK Roth', state:'Bayern'},
	{region:'LK Rottal-Inn', state:'Bayern'},
	{region:'LK Rottweil', state:'Baden-Württemberg'},
	{region:'LK Saale-Holzland-Kreis', state:'Thüringen'},
	{region:'LK Saalekreis', state:'Sachsen-Anhalt'},
	{region:'LK Saale-Orla-Kreis', state:'Thüringen'},
	{region:'LK Saalfeld-Rudolstadt', state:'Thüringen'},
	{region:'LK Saarbrücken', state:'Saarland'},
	{region:'LK Saarlouis', state:'Saarland'},
	{region:'LK Saarpfalz-Kreis', state:'Saarland'},
	{region:'LK Sächsische Schweiz-Osterzgebirge', state:'Sachsen'},
	{region:'LK Salzlandkreis', state:'Sachsen-Anhalt'},
	{region:'LK Schaumburg', state:'Niedersachsen'},
	{region:'LK Schleswig-Flensburg', state:'Schleswig-Holstein'},
	{region:'LK Schmalkalden-Meiningen', state:'Thüringen'},
	{region:'LK Schwalm-Eder-Kreis', state:'Hessen'},
	{region:'LK Schwandorf', state:'Bayern'},
	{region:'LK Schwarzwald-Baar-Kreis', state:'Baden-Württemberg'},
	{region:'LK Schwäbisch Hall', state:'Baden-Württemberg'},
	{region:'LK Schweinfurt', state:'Bayern'},
	{region:'LK Segeberg', state:'Schleswig-Holstein'},
	{region:'LK Siegen-Wittgenstein', state:'Nordrhein-Westfalen'},
	{region:'LK Sigmaringen', state:'Baden-Württemberg'},
	{region:'LK Soest', state:'Nordrhein-Westfalen'},
	{region:'LK Sömmerda', state:'Thüringen'},
	{region:'LK Sonneberg', state:'Thüringen'},
	{region:'LK Spree-Neiße', state:'Brandenburg'},
	{region:'LK St. Wendel', state:'Saarland'},
	{region:'LK Stade', state:'Niedersachsen'},
	{region:'LK Starnberg', state:'Bayern'},
	{region:'LK Steinburg', state:'Schleswig-Holstein'},
	{region:'LK Steinfurt', state:'Nordrhein-Westfalen'},
	{region:'LK Stendal', state:'Sachsen-Anhalt'},
	{region:'LK Stormarn', state:'Schleswig-Holstein'},
	{region:'LK Straubing-Bogen', state:'Bayern'},
	{region:'LK Südliche Weinstraße', state:'Rheinland-Pfalz'},
	{region:'LK Südwestpfalz', state:'Rheinland-Pfalz'},
	{region:'LK Teltow-Fläming', state:'Brandenburg'},
	{region:'LK Tirschenreuth', state:'Bayern'},
	{region:'LK Traunstein', state:'Bayern'},
	{region:'LK Trier-Saarburg', state:'Rheinland-Pfalz'},
	{region:'LK Tübingen', state:'Baden-Württemberg'},
	{region:'LK Tuttlingen', state:'Baden-Württemberg'},
	{region:'LK Uckermark', state:'Brandenburg'},
	{region:'LK Uelzen', state:'Niedersachsen'},
	{region:'LK Unna', state:'Nordrhein-Westfalen'},
	{region:'LK Unstrut-Hainich-Kreis', state:'Thüringen'},
	{region:'LK Unterallgäu', state:'Bayern'},
	{region:'LK Vechta', state:'Niedersachsen'},
	{region:'LK Verden', state:'Niedersachsen'},
	{region:'LK Viersen', state:'Nordrhein-Westfalen'},
	{region:'LK Vogelsbergkreis', state:'Hessen'},
	{region:'LK Vogtlandkreis', state:'Sachsen'},
	{region:'LK Vorpommern-Greifswald', state:'Mecklenburg-Vorpommern'},
	{region:'LK Vorpommern-Rügen', state:'Mecklenburg-Vorpommern'},
	{region:'LK Vulkaneifel', state:'Rheinland-Pfalz'},
	{region:'LK Waldeck-Frankenberg', state:'Hessen'},
	{region:'LK Waldshut', state:'Baden-Württemberg'},
	{region:'LK Warendorf', state:'Nordrhein-Westfalen'},
	{region:'LK Wartburgkreis', state:'Thüringen'},
	{region:'LK Weilheim-Schongau', state:'Bayern'},
	{region:'LK Weimarer Land', state:'Thüringen'},
	{region:'LK Weißenburg-Gunzenhausen', state:'Bayern'},
	{region:'LK Werra-Meißner-Kreis', state:'Hessen'},
	{region:'LK Wesel', state:'Nordrhein-Westfalen'},
	{region:'LK Wesermarsch', state:'Niedersachsen'},
	{region:'LK Westerwaldkreis', state:'Rheinland-Pfalz'},
	{region:'LK Wetteraukreis', state:'Hessen'},
	{region:'LK Wittenberg', state:'Sachsen-Anhalt'},
	{region:'LK Wittmund', state:'Niedersachsen'},
	{region:'LK Wolfenbüttel', state:'Niedersachsen'},
	{region:'LK Wunsiedel im Fichtelgebirge', state:'Bayern'},
	{region:'LK Würzburg', state:'Bayern'},
	{region:'LK Zollernalbkreis', state:'Baden-Württemberg'},
	{region:'LK Zwickau', state:'Sachsen'},
	{region:'SK Aachen', state:'Nordrhein-Westfalen'},
	{region:'SK Amberg', state:'Bayern'},
	{region:'SK Ansbach', state:'Bayern'},
	{region:'SK Aschaffenburg', state:'Bayern'},
	{region:'SK Augsburg', state:'Bayern'},
	{region:'SK Baden-Baden', state:'Baden-Württemberg'},
	{region:'SK Bamberg', state:'Bayern'},
	{region:'SK Bayreuth', state:'Bayern'},
	{region:'SK Berlin', state:'Berlin'},
	{region:'SK Bielefeld', state:'Nordrhein-Westfalen'},
	{region:'SK Bochum', state:'Nordrhein-Westfalen'},
	{region:'SK Bonn', state:'Nordrhein-Westfalen'},
	{region:'SK Bottrop', state:'Nordrhein-Westfalen'},
	{region:'SK Brandenburg an der Havel', state:'Brandenburg'},
	{region:'SK Braunschweig', state:'Niedersachsen'},
	{region:'SK Bremen', state:'Bremen'},
	{region:'SK Bremerhaven', state:'Bremen'},
	{region:'SK Chemnitz', state:'Sachsen'},
	{region:'SK Coburg', state:'Bayern'},
	{region:'SK Cottbus', state:'Brandenburg'},
	{region:'SK Darmstadt', state:'Hessen'},
	{region:'SK Delmenhorst', state:'Niedersachsen'},
	{region:'SK Dessau-Roßlau', state:'Sachsen-Anhalt'},
	{region:'SK Dortmund', state:'Nordrhein-Westfalen'},
	{region:'SK Dresden', state:'Sachsen'},
	{region:'SK Duisburg', state:'Nordrhein-Westfalen'},
	{region:'SK Düsseldorf', state:'Nordrhein-Westfalen'},
	{region:'SK Eisenach', state:'Thüringen'},
	{region:'SK Emden', state:'Niedersachsen'},
	{region:'SK Erfurt', state:'Thüringen'},
	{region:'SK Erlangen', state:'Bayern'},
	{region:'SK Essen', state:'Nordrhein-Westfalen'},
	{region:'SK Flensburg', state:'Schleswig-Holstein'},
	{region:'SK Frankenthal (Pfalz)', state:'Rheinland-Pfalz'},
	{region:'SK Frankfurt (Oder)', state:'Brandenburg'},
	{region:'SK Frankfurt am Main', state:'Hessen'},
	{region:'SK Freiburg im Breisgau', state:'Baden-Württemberg'},
	{region:'SK Fürth', state:'Bayern'},
	{region:'SK Gelsenkirchen', state:'Nordrhein-Westfalen'},
	{region:'SK Gera', state:'Thüringen'},
	{region:'SK Göttingen', state:'Niedersachsen'},
	{region:'SK Hagen', state:'Nordrhein-Westfalen'},
	{region:'SK Halle (Saale)', state:'Sachsen-Anhalt'},
	{region:'SK Hamburg', state:'Hamburg'},
	{region:'SK Hamm', state:'Nordrhein-Westfalen'},
	{region:'SK Hannover', state:'Niedersachsen'},
	{region:'SK Heidelberg', state:'Baden-Württemberg'},
	{region:'SK Heilbronn', state:'Baden-Württemberg'},
	{region:'SK Herne', state:'Nordrhein-Westfalen'},
	{region:'SK Hof', state:'Bayern'},
	{region:'SK Ingolstadt', state:'Bayern'},
	{region:'SK Jena', state:'Thüringen'},
	{region:'SK Kaiserslautern', state:'Rheinland-Pfalz'},
	{region:'SK Karlsruhe', state:'Baden-Württemberg'},
	{region:'SK Kassel', state:'Hessen'},
	{region:'SK Kaufbeuren', state:'Bayern'},
	{region:'SK Kempten (Allgäu)', state:'Bayern'},
	{region:'SK Kiel', state:'Schleswig-Holstein'},
	{region:'SK Koblenz', state:'Rheinland-Pfalz'},
	{region:'SK Köln', state:'Nordrhein-Westfalen'},
	{region:'SK Krefeld', state:'Nordrhein-Westfalen'},
	{region:'SK Landau in der Pfalz', state:'Rheinland-Pfalz'},
	{region:'SK Landshut', state:'Bayern'},
	{region:'SK Leipzig', state:'Sachsen'},
	{region:'SK Leverkusen', state:'Nordrhein-Westfalen'},
	{region:'SK Lübeck', state:'Schleswig-Holstein'},
	{region:'SK Ludwigshafen am Rhein', state:'Rheinland-Pfalz'},
	{region:'SK Magdeburg', state:'Sachsen-Anhalt'},
	{region:'SK Mainz', state:'Rheinland-Pfalz'},
	{region:'SK Mannheim', state:'Baden-Württemberg'},
	{region:'SK Memmingen', state:'Bayern'},
	{region:'SK Mönchengladbach', state:'Nordrhein-Westfalen'},
	{region:'SK Mülheim an der Ruhr', state:'Nordrhein-Westfalen'},
	{region:'SK München', state:'Bayern'},
	{region:'SK Münster', state:'Nordrhein-Westfalen'},
	{region:'SK Neumünster', state:'Schleswig-Holstein'},
	{region:'SK Neustadt an der Weinstraße', state:'Rheinland-Pfalz'},
	{region:'SK Nürnberg', state:'Bayern'},
	{region:'SK Oberhausen', state:'Nordrhein-Westfalen'},
	{region:'SK Offenbach am Main', state:'Hessen'},
	{region:'SK Oldenburg', state:'Niedersachsen'},
	{region:'SK Osnabrück', state:'Niedersachsen'},
	{region:'SK Passau', state:'Bayern'},
	{region:'SK Pforzheim', state:'Baden-Württemberg'},
	{region:'SK Pirmasens', state:'Rheinland-Pfalz'},
	{region:'SK Potsdam', state:'Brandenburg'},
	{region:'SK Regensburg', state:'Bayern'},
	{region:'SK Remscheid', state:'Nordrhein-Westfalen'},
	{region:'SK Rosenheim', state:'Bayern'},
	{region:'SK Rostock', state:'Mecklenburg-Vorpommern'},
	{region:'SK Salzgitter', state:'Niedersachsen'},
	{region:'SK Schwabach', state:'Bayern'},
	{region:'SK Schweinfurt', state:'Bayern'},
	{region:'SK Schwerin', state:'Mecklenburg-Vorpommern'},
	{region:'SK Solingen', state:'Nordrhein-Westfalen'},
	{region:'SK Speyer', state:'Rheinland-Pfalz'},
	{region:'SK Straubing', state:'Bayern'},
	{region:'SK Stuttgart', state:'Baden-Württemberg'},
	{region:'SK Suhl', state:'Thüringen'},
	{region:'SK Trier', state:'Rheinland-Pfalz'},
	{region:'SK Ulm', state:'Baden-Württemberg'},
	{region:'SK Weiden in der Oberpfalz', state:'Bayern'},
	{region:'SK Weimar', state:'Thüringen'},
	{region:'SK Wiesbaden', state:'Hessen'},
	{region:'SK Wilhelmshaven', state:'Niedersachsen'},
	{region:'SK Wolfsburg', state:'Niedersachsen'},
	{region:'SK Worms', state:'Rheinland-Pfalz'},
	{region:'SK Wuppertal', state:'Nordrhein-Westfalen'},
	{region:'SK Würzburg', state:'Bayern'},
	{region:'SK Zweibrücken', state:'Rheinland-Pfalz'}
];

export const stateNames = ['Baden-Württemberg','Bayern','Berlin','Brandenburg','Bremen','Hamburg','Hessen','Mecklenburg-Vorpommern','Niedersachsen','Nordrhein-Westfalen','Rheinland-Pfalz','Saarland','Sachsen','Sachsen-Anhalt','Schleswig-Holstein','Thüringen'];

export const states = {
	'Baden-Württemberg': [
		'LK Alb-Donau-Kreis',
		'LK Biberach',
		'LK Böblingen',
		'LK Bodenseekreis',
		'LK Breisgau-Hochschwarzwald',
		'LK Calw',
		'LK Emmendingen',
		'LK Enzkreis',
		'LK Esslingen',
		'LK Freudenstadt',
		'LK Göppingen',
		'LK Heidenheim',
		'LK Heilbronn',
		'LK Hohenlohekreis',
		'LK Karlsruhe',
		'LK Konstanz',
		'LK Lörrach',
		'LK Ludwigsburg',
		'LK Main-Tauber-Kreis',
		'LK Neckar-Odenwald-Kreis',
		'LK Ortenaukreis',
		'LK Ostalbkreis',
		'LK Rastatt',
		'LK Ravensburg',
		'LK Rems-Murr-Kreis',
		'LK Reutlingen',
		'LK Rhein-Neckar-Kreis',
		'LK Rottweil',
		'LK Schwarzwald-Baar-Kreis',
		'LK Schwäbisch Hall',
		'LK Sigmaringen',
		'LK Tübingen',
		'LK Tuttlingen',
		'LK Waldshut',
		'LK Zollernalbkreis',
		'SK Baden-Baden',
		'SK Freiburg im Breisgau',
		'SK Heidelberg',
		'SK Heilbronn',
		'SK Karlsruhe',
		'SK Mannheim',
		'SK Pforzheim',
		'SK Stuttgart',
		'SK Ulm'
	],
	'Bayern': [
		'LK Aichach-Friedberg',
		'LK Altötting',
		'LK Amberg-Sulzbach',
		'LK Ansbach',
		'LK Aschaffenburg',
		'LK Augsburg',
		'LK Bad Kissingen',
		'LK Bad Tölz-Wolfratshausen',
		'LK Bamberg',
		'LK Bayreuth',
		'LK Berchtesgadener Land',
		'LK Cham',
		'LK Coburg',
		'LK Dachau',
		'LK Deggendorf',
		'LK Dillingen an der Donau',
		'LK Dingolfing-Landau',
		'LK Donau-Ries',
		'LK Ebersberg',
		'LK Eichstätt',
		'LK Erding',
		'LK Erlangen-Höchstadt',
		'LK Forchheim',
		'LK Freising',
		'LK Freyung-Grafenau',
		'LK Fürstenfeldbruck',
		'LK Fürth',
		'LK Garmisch-Partenkirchen',
		'LK Günzburg',
		'LK Haßberge',
		'LK Hof',
		'LK Kelheim',
		'LK Kitzingen',
		'LK Kronach',
		'LK Kulmbach',
		'LK Landsberg am Lech',
		'LK Landshut',
		'LK Lichtenfels',
		'LK Lindau (Bodensee)',
		'LK Main-Spessart',
		'LK Miesbach',
		'LK Miltenberg',
		'LK Mühldorf am Inn',
		'LK München',
		'LK Neu-Ulm',
		'LK Neuburg-Schrobenhausen',
		'LK Neumarkt in der Oberpfalz',
		'LK Neustadt an der Aisch-Bad Windsheim',
		'LK Neustadt an der Waldnaab',
		'LK Nürnberger Land',
		'LK Oberallgäu',
		'LK Ostallgäu',
		'LK Passau',
		'LK Pfaffenhofen an der Ilm',
		'LK Regen',
		'LK Regensburg',
		'LK Rhön-Grabfeld',
		'LK Rosenheim',
		'LK Roth',
		'LK Rottal-Inn',
		'LK Schwandorf',
		'LK Schweinfurt',
		'LK Starnberg',
		'LK Straubing-Bogen',
		'LK Tirschenreuth',
		'LK Traunstein',
		'LK Unterallgäu',
		'LK Weilheim-Schongau',
		'LK Weißenburg-Gunzenhausen',
		'LK Wunsiedel im Fichtelgebirge',
		'LK Würzburg',
		'SK Amberg',
		'SK Ansbach',
		'SK Aschaffenburg',
		'SK Augsburg',
		'SK Bamberg',
		'SK Bayreuth',
		'SK Coburg',
		'SK Erlangen',
		'SK Fürth',
		'SK Hof',
		'SK Ingolstadt',
		'SK Kaufbeuren',
		'SK Kempten (Allgäu)',
		'SK Landshut',
		'SK Memmingen',
		'SK München',
		'SK Nürnberg',
		'SK Passau',
		'SK Regensburg',
		'SK Rosenheim',
		'SK Schwabach',
		'SK Schweinfurt',
		'SK Straubing',
		'SK Weiden in der Oberpfalz',
		'SK Würzburg'
	],
	'Berlin': [
		'SK Berlin'
	],
	'Brandenburg': [
		'LK Barnim',
		'LK Dahme-Spreewald',
		'LK Elbe-Elster',
		'LK Havelland',
		'LK Märkisch-Oderland',
		'LK Oberhavel',
		'LK Oberspreewald-Lausitz',
		'LK Oder-Spree',
		'LK Ostprignitz-Ruppin',
		'LK Potsdam-Mittelmark',
		'LK Prignitz',
		'LK Spree-Neiße',
		'LK Teltow-Fläming',
		'LK Uckermark',
		'SK Brandenburg an der Havel',
		'SK Cottbus',
		'SK Frankfurt (Oder)',
		'SK Potsdam'
	],
	'Bremen': [
		'SK Bremen',
		'SK Bremerhaven'
	],
	'Hamburg': [
		'SK Hamburg'
	],
	'Hessen': [
		'LK Bergstraße',
		'LK Darmstadt-Dieburg',
		'LK Fulda',
		'LK Gießen',
		'LK Groß-Gerau',
		'LK Hersfeld-Rotenburg',
		'LK Hochtaunuskreis',
		'LK Kassel',
		'LK Lahn-Dill-Kreis',
		'LK Limburg-Weilburg',
		'LK Main-Kinzig-Kreis',
		'LK Main-Taunus-Kreis',
		'LK Marburg-Biedenkopf',
		'LK Odenwaldkreis',
		'LK Offenbach',
		'LK Rheingau-Taunus-Kreis',
		'LK Schwalm-Eder-Kreis',
		'LK Vogelsbergkreis',
		'LK Waldeck-Frankenberg',
		'LK Werra-Meißner-Kreis',
		'LK Wetteraukreis',
		'SK Darmstadt',
		'SK Frankfurt am Main',
		'SK Kassel',
		'SK Offenbach am Main',
		'SK Wiesbaden'
	],
	'Mecklenburg-Vorpommern': [
		'LK Ludwigslust-Parchim',
		'LK Mecklenburgische Seenplatte',
		'LK Nordwestmecklenburg',
		'LK Rostock',
		'LK Vorpommern-Greifswald',
		'LK Vorpommern-Rügen',
		'SK Rostock',
		'SK Schwerin'
	],
	'Niedersachsen': [
		'LK Ammerland',
		'LK Aurich',
		'LK Celle',
		'LK Cloppenburg',
		'LK Cuxhaven',
		'LK Diepholz',
		'LK Emsland',
		'LK Friesland',
		'LK Gifhorn',
		'LK Goslar',
		'LK Göttingen',
		'LK Grafschaft Bentheim',
		'LK Hameln-Pyrmont',
		'LK Hannover',
		'LK Harburg',
		'LK Heidekreis',
		'LK Helmstedt',
		'LK Hildesheim',
		'LK Holzminden',
		'LK Leer',
		'LK Lüchow-Dannenberg',
		'LK Lüneburg',
		'LK Nienburg/Weser',
		'LK Northeim',
		'LK Oldenburg',
		'LK Osnabrück',
		'LK Osterholz',
		'LK Peine',
		'LK Rotenburg (Wümme)',
		'LK Schaumburg',
		'LK Stade',
		'LK Uelzen',
		'LK Vechta',
		'LK Verden',
		'LK Wesermarsch',
		'LK Wittmund',
		'LK Wolfenbüttel',
		'SK Braunschweig',
		'SK Delmenhorst',
		'SK Emden',
		'SK Göttingen',
		'SK Hannover',
		'SK Oldenburg',
		'SK Osnabrück',
		'SK Salzgitter',
		'SK Wilhelmshaven',
		'SK Wolfsburg'
	],
	'Nordrhein-Westfalen': [
		'LK Aachen',
		'LK Borken',
		'LK Coesfeld',
		'LK Düren',
		'LK Ennepe-Ruhr-Kreis',
		'LK Euskirchen',
		'LK Gütersloh',
		'LK Heinsberg',
		'LK Herford',
		'LK Hochsauerlandkreis',
		'LK Höxter',
		'LK Kleve',
		'LK Lippe',
		'LK Märkischer Kreis',
		'LK Mettmann',
		'LK Minden-Lübbecke',
		'LK Oberbergischer Kreis',
		'LK Olpe',
		'LK Paderborn',
		'LK Recklinghausen',
		'LK Rhein-Erft-Kreis',
		'LK Rheinisch-Bergischer Kreis',
		'LK Rhein-Kreis Neuss',
		'LK Rhein-Sieg-Kreis',
		'LK Siegen-Wittgenstein',
		'LK Soest',
		'LK Steinfurt',
		'LK Unna',
		'LK Viersen',
		'LK Warendorf',
		'LK Wesel',
		'SK Aachen',
		'SK Bielefeld',
		'SK Bochum',
		'SK Bonn',
		'SK Bottrop',
		'SK Dortmund',
		'SK Duisburg',
		'SK Düsseldorf',
		'SK Essen',
		'SK Gelsenkirchen',
		'SK Hagen',
		'SK Hamm',
		'SK Herne',
		'SK Köln',
		'SK Krefeld',
		'SK Leverkusen',
		'SK Mönchengladbach',
		'SK Mülheim an der Ruhr',
		'SK Münster',
		'SK Oberhausen',
		'SK Remscheid',
		'SK Solingen',
		'SK Wuppertal'
	],
	'Rheinland-Pfalz': [
		'LK Ahrweiler',
		'LK Altenkirchen',
		'LK Alzey-Worms',
		'LK Bad Dürkheim',
		'LK Bad Kreuznach',
		'LK Bernkastel-Wittlich',
		'LK Birkenfeld',
		'LK Cochem-Zell',
		'LK Donnersbergkreis',
		'LK Eifelkreis Bitburg-Prüm',
		'LK Germersheim',
		'LK Kaiserslautern',
		'LK Kusel',
		'LK Mainz-Bingen',
		'LK Mayen-Koblenz',
		'LK Neuwied',
		'LK Rhein-Hunsrück-Kreis',
		'LK Rhein-Lahn-Kreis',
		'LK Rhein-Pfalz-Kreis',
		'LK Südliche Weinstraße',
		'LK Südwestpfalz',
		'LK Trier-Saarburg',
		'LK Vulkaneifel',
		'LK Westerwaldkreis',
		'SK Frankenthal (Pfalz)',
		'SK Kaiserslautern',
		'SK Koblenz',
		'SK Landau in der Pfalz',
		'SK Ludwigshafen am Rhein',
		'SK Mainz',
		'SK Neustadt an der Weinstraße',
		'SK Pirmasens',
		'SK Speyer',
		'SK Trier',
		'SK Worms',
		'SK Zweibrücken'
	],
	'Saarland': [
		'LK Merzig-Wadern',
		'LK Neunkirchen',
		'LK Saarbrücken',
		'LK Saarlouis',
		'LK Saarpfalz-Kreis',
		'LK St. Wendel'
	],
	'Sachsen': [
		'LK Bautzen',
		'LK Erzgebirgskreis',
		'LK Görlitz',
		'LK Leipzig',
		'LK Meißen',
		'LK Mittelsachsen',
		'LK Nordsachsen',
		'LK Sächsische Schweiz-Osterzgebirge',
		'LK Vogtlandkreis',
		'LK Zwickau',
		'SK Chemnitz',
		'SK Dresden',
		'SK Leipzig'
	],
	'Sachsen-Anhalt': [
		'LK Altmarkkreis Salzwedel',
		'LK Anhalt-Bitterfeld',
		'LK Börde',
		'LK Burgenlandkreis',
		'LK Harz',
		'LK Jerichower Land',
		'LK Mansfeld-Südharz',
		'LK Saalekreis',
		'LK Salzlandkreis',
		'LK Stendal',
		'LK Wittenberg',
		'SK Dessau-Roßlau',
		'SK Halle (Saale)',
		'SK Magdeburg'
	],
	'Schleswig-Holstein': [
		'LK Dithmarschen',
		'LK Herzogtum Lauenburg',
		'LK Nordfriesland',
		'LK Ostholstein',
		'LK Pinneberg',
		'LK Plön',
		'LK Rendsburg-Eckernförde',
		'LK Schleswig-Flensburg',
		'LK Segeberg',
		'LK Steinburg',
		'LK Stormarn',
		'SK Flensburg',
		'SK Kiel',
		'SK Lübeck',
		'SK Neumünster'
	],
	'Thüringen': [
		'LK Altenburger Land',
		'LK Eichsfeld',
		'LK Gotha',
		'LK Greiz',
		'LK Hildburghausen',
		'LK Ilm-Kreis',
		'LK Kyffhäuserkreis',
		'LK Nordhausen',
		'LK Saale-Holzland-Kreis',
		'LK Saale-Orla-Kreis',
		'LK Saalfeld-Rudolstadt',
		'LK Schmalkalden-Meiningen',
		'LK Sömmerda',
		'LK Sonneberg',
		'LK Unstrut-Hainich-Kreis',
		'LK Wartburgkreis',
		'LK Weimarer Land',
		'SK Eisenach',
		'SK Erfurt',
		'SK Gera',
		'SK Jena',
		'SK Suhl',
		'SK Weimar'
	]
};
