import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {formatRange, formatDate} from '../utils/utils';
import {danceData, regions, stateNames, states} from '../utils/data';

export default function SearchResult(props) {
	function info(lbl, val, key) {
		return <span key={key}><b>{lbl}:</b> {val ?? "keine Angabe"}</span>
	}

	let result=props.result;
	var cn = "searchResult container";
	if(props.marked) cn += " marked";

	return (
		<div className={cn} onClick={props.click}>
			<h2><span>Erstellt am {formatDate(result.created)}</span></h2>
			<div className="filler">
				{result.img && (
					<div style={{width:"150px", height: "150px", marginRight:"30px"}}>
						<img style={{width:"100%", height:"100%", objectFit: "contain"}} src={"img/user/" + result.img}/>
					</div>
				)}
				<div className="searchResultInfo" style={{marginRight:"50px"}}>
					<h3 style={{marginTop:"0px"}}>{props.typ == "search" ? 'Profil:' : 'Allgemein:'} </h3>
					{result.name && info('Name', result.name)}
					{info('Alter', result.age)}
					{info('Größe', result.size)}
					{info('Geschlecht', result.gender)}
					{props.typ == "search" && info('PLZ', result.plz)}
					{result.typ == 0 && result.datum && info('Datum', formatDate(result.datum))}
					{props.typ == "search" && info('Zuletzt online', formatDate(result.lastOnline))}
				</div>
				<div className="searchResultInfo">
					<h3 style={{marginTop:"0px"}}>Kenntnisse:</h3>
					{
						result.dances ? result.dances.map(d => {
							var dd = danceData[d[0]-1];
							var lvl1 = d[1] == 0 ? null : dd.levels[d[1]];
							var lvl2 = d[2] == dd.levels.length-1 ? null : dd.levels[d[2]];
							return info(dd.name, formatRange(lvl1, lvl2), d[0]);
						}) : danceData.map(dd => {
							var lvl = result['dance' + dd.id];
							return lvl && info(dd.name, dd.levels[lvl], dd.id);
						})
					}
				</div>
				<br/>
				{result.regions && <p><b>Gebiet</b>: {result.regions.join(", ")}</p>}
				<div className="searchResultText">
					<xmp>{result.searchtext}</xmp>
				</div>
			</div>
		</div>
	)
}
