import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {
	HashRouter as Router,
	Switch,
	Route,
	Link,
	Redirect,
	withRouter
} from "react-router-dom";
import './Style.scss';

import Login from './pages/Login';
import Reset from './pages/Reset'
import Register from './pages/Register';
import Confirm from './pages/Confirm';
import Search from './pages/MainSearch';
import Messages from './pages/Messages';
import User from './pages/User';
import Terms from './pages/Terms';
import Impressum from './pages/Impressum';
import Test from './pages/Test';
import Profile from './pages/Profile';
import { createHashHistory } from 'history'
import { useSelector, useDispatch } from 'react-redux';
import { getId, reset, showDlg, setDialog, setId } from './utils/Redux';
import SearchDlg from './pages/SearchDlg';
import {query, MySelect} from './utils/utils';
import HowTo from './utils/HowTo';


function App(props) {
	const dispatch = useDispatch();
	const user = useSelector(getId);
	const [msg, setMsg] = useState(0);
	const [name, setName] = useState(null);


	const showDialog = useSelector(showDlg);
	const history = props.history;

	const styles = {
		display: "flex",
		flexDirection: "column",
		justifyContent: "start",
		fontFamily: "Arial"
	}
	const dlgStyles = {position:"absolute",
		top:0,
		left:0,
		width:"100%",
		height:"100%",
		backgroundColor: "#0008",
		justifyContent: "center",
		alignItems: "center",
		display: showDialog ? "flex" : "none"
	};


	useEffect(() => {
		//dispatch(reset(null));
		query('check', {}, res => {
			if(user != res.id) dispatch(setId(res.id));
			setMsg(res.msg);
			setName(res.name);
		}, () => dispatch(reset(null)))
	})

	useEffect(() => {
		document.title = "Tanzpartner Wanted - Die kostenlose Tanzpartnerbörse";
		dispatch(setDialog(false));
	},[])

	var def = [
		<Route key="impressum" path="/impressum">
			<Impressum />
		</Route>,
		<Route key="Terms" path="/Terms">
			<Terms />
		</Route>
	]

	var switch1 = (
		<Switch>
			{def}
			<Route path="/register">
				<Register />
			</Route>
			<Route path="/reset/:id/:key">
				<Reset/>
			</Route>
			<Route path="/confirm/:id/:key">
				<Confirm/>
			</Route>
			<Route path="/">
				<Login/>
			</Route>
		</Switch>
	)


	var switch2 = (
		<Switch>
			{def}
			<Route path="/user/:id/:sid">
				<User />
			</Route>
			<Route path="/user/:id">
				<User />
			</Route>
			<Route path="/messages/:id">
				<Messages />
			</Route>
			<Route path="/messages">
				<Messages />
			</Route>
			<Route path="/profile">
				<Profile history={props.history}/>
			</Route>
			<Route path="/search/:id">
				<Search />
			</Route>
			<Route path="/search">
				<Search />
			</Route>
			<Route path="/logout">
				<Logout history={props.history}/>
			</Route>
			<Route path="/">
				<div >
					{name && <h1 style={{margin:"100px", fontSize:"60px"}}>Willkommen {name}</h1>}
					<HowTo/>
				</div>
			</Route>
		</Switch>
	)

	var header1 = (
		<div id="header">
			<div id="headerLeft"> </div>
			<div id="headerCenter">
				<Link to="/"><img style={{height:"79px", marginBottom:"-11px"}} src="img/Logo.png"/></Link>
			</div>
			<div id="headerRight"><Link to="/login">Login</Link></div>
		</div>
	)
	// <div style={{fontSize:"30px", marginTop:"-67px", marginLeft:"20px"}}>Tanzpartner</div>
	// <div style={{fontSize:"30px", marginTop:"0px", marginLeft:"20px"}}>Wanted</div>
	var header2 = (
		<div id="header">
			<div id="headerLeft">
				<Link  style={{display:"flex", alignItems:"center"}} className="btn" to="/search">
					<img src="img/search.png" style={{height:"30px", marginRight:"10px"}}/>
					Hauptsuche
				</Link>
				<div></div>
			</div>
			<div id="headerCenter">
				<Link to="/"><img style={{height:"79px", marginBottom:"-11px"}} src="img/Logo.png"/></Link>

			</div>
			<div id="headerRight" style={{textAlign:"right"}}>
				<Link style={{display:"flex", alignItems:"center"}} className="btn" to="/messages">
					<img src="img/msg.png" style={{height:"20px", marginRight:"10px"}}/>
					Nachrichten
				</Link>
				{msg>0 && <div className="indicator">{msg}</div>}
				<Link style={{display:"flex", alignItems:"center"}} className="btn" to="/profile">
					<img src="img/profile.png" style={{height:"30px", marginRight:"10px"}}/>
					Profil
				</Link>
				<Link style={{display:"flex", alignItems:"center"}} className="btn" to="/logout">
					<img src="img/logout.png" style={{height:"30px", marginRight:"10px"}}/>
					Logout
				</Link>
			</div>
		</div>
	)

	return (
		<div className="App" style={styles}>
				{(user < 0) ? header1 : header2}
				<div id="main">
					{(user < 0) ? switch1 : switch2}
				</div>
				<div id="footer">
					<Link to="impressum">Impressum</Link>
						&nbsp;&nbsp;&nbsp;&nbsp;
					<Link to="Terms">AGB & Datenschutz</Link>
				</div>
				<div id="dialog" style={dlgStyles}>
					<SearchDlg show={showDialog == 1 ? 1 : 0} footer={true}/>
					<div id="dlgContent" style={{backgroundColor:"white", display:showDialog == 2 ? 'block' : 'none' }}></div>
				</div>
		</div>
	)

}

function Logout(props) {
	const dispatch = useDispatch();
	useEffect(() => {
		query('logout', {}, () =>	{
			dispatch(reset(null))
			props.history.push("/login");
		});
	},[]);
	return null;
}

export default withRouter(App);
