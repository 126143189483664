import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {getSearch, setSearch} from '../utils/Redux';
import {MyInput, MySelect, MyTextArea, MyTr, query, formatRange, createOptions} from '../utils/utils';
import {danceData, regions as regionInfo, stateNames, states as stateInfo} from '../utils/data';
import { useSelector, useDispatch } from 'react-redux';
import Tooltip from '../utils/Tooltip';
import {Link} from "react-router-dom";


export default function SearchDlg(props) {
	const search = useSelector(getSearch);
	const dispatch = useDispatch();
	function setValue(val, idx) {
		var v = (val.length == 0) ? null : val;
		if(idx == "typ" && props.setType) props.setType(val);
		dispatch(setSearch({...search,[idx]:v}));
	}

	const genders = search.genders;

	const [folded, setFolded] = props.folded ?? [[false, false, false], null];
	function setGender(v,i) {
		var g = [...genders];
		if(v == 0) g.splice(g.indexOf(i), 1);
		else g.push(i);
		setValue(g, 'genders');
	}

	function toggle(i) {
		var temp = [...folded];
		temp[i] = !temp[i];
		setFolded(temp);
	}

	var clazzes = [];
	for(var i = 0; i < 3; i++) clazzes[i] = folded[i] ? "container folded" : "container";

	const generalView = (
		<div className={clazzes[0]} style={{width:"370px"}}>
			<h2><span>Einstellungen</span> {props.show == 2 && <button className="btn" style={{fontSize:"35px", padding:"0px 15px"}}  onClick={e => {e.preventDefault(); toggle(0)}}>{folded[0] ? '+' : '-'}</button> }</h2>
			<div className="filler">
				<table style={{whiteSpace:"nowrap"}}><tbody>
					<MyTr lbl="Art"><MyInput type="radio" value="0" checked={search.typ==0} set={setValue} idx="typ" /> Veranstaltung</MyTr>
					<MyTr lbl=""><MyInput type="radio" value="1" checked={search.typ==1} set={setValue} idx="typ" /> regelm. Kurs/Training</MyTr>
					<MyTr lbl="minimales Alter"><MyInput type="number" value={search.minAge ?? ''} set={setValue} idx="minAge" /></MyTr>
					<MyTr lbl="maximales Alter"><MyInput type="number" value={search.maxAge ?? ''} set={setValue} idx="maxAge" /></MyTr>
					<MyTr lbl="minimale Größe"><MyInput type="number" value={search.minSize ?? ''} set={setValue} idx="minSize" /></MyTr>
					<MyTr lbl="maximale Größe"><MyInput type="number" value={search.maxSize ?? ''} set={setValue} idx="maxSize" /></MyTr>
					<MyTr lbl="Geschlecht">
						<MyInput type="checkbox" checked={genders == undefined || genders.indexOf('w') >= 0} set={setGender} idx="w"/> weiblich
						<MyInput type="checkbox" checked={genders == undefined || genders.indexOf('m') >= 0} set={setGender} idx="m"/> männlich
					</MyTr>
					<tr><td></td><td><MyInput type="checkbox" checked={genders == undefined || genders.indexOf('d') >= 0} set={setGender} idx="d"/> andere</td></tr>
					{search.typ == 0 && <MyTr lbl="Datum"><MyInput type="date" value={search.datum ?? ''} set={setValue} idx="datum" /></MyTr>}
					{props.show == 1 && <MyTr lbl="öffentlich" tooltip="Wenn aktiviert, kann dieses Gesuch in der Hauptsuche gefunden werden und wird im Profil angezeigt"><MyInput type="checkbox" checked={search.visible == 1} set={setValue} idx="visible" /></MyTr>}
					{props.show == 1 && <MyTr lbl="Benachrichtigungen" tooltip="Wenn aktiviert, bekommen Sie eine Mail wann immer jemand ein passendes öffentliches Gesuch erstellt"><MyInput type="checkbox" checked={search.notify == 1} set={setValue} idx="notify" /></MyTr>}
				</tbody></table>
			</div>
		</div>
	)


	const dances = search.dances ?? [];

	function setDanceValue(id, val, idx) {
		var copy = [...dances]
		var dance = [...copy[id]];
		dance[idx] = val;
		if(idx==0) {
			dance[1]=0;
			dance[2]=danceData[val-1].levels.length-1;
		}
		copy[id] = dance;
		dispatch(setSearch({...search,dances:copy}));
	}

	function deleteDance(id) {
		var copy = [...dances]
		copy.splice(id, 1);
		dispatch(setSearch({...search,dances:copy}));
	}

	function newDance() {
		var copy = [...dances]
		var dance = danceData.find(dd => dances.findIndex(d => d[0] == dd.id) < 0).id;
		copy.push([dance,0,danceData[dance-1].levels.length-1]);
		dispatch(setSearch({...search,dances:copy}));
	}
	var style= {
		minWidth: "calc(100% - 10px)"
	}

	var remainingDances = danceData.filter(dd => dances.findIndex(d => d[0] == dd.id) < 0);
	const dancesView = (
		<div className={clazzes[1]} style={{margin:"0px 10px", width:"600px"}}>
			<h2 style={{paddingLeft:"10px"}}>
				<span>
					Kenntnisse
					<Tooltip styles={{margin:"-10px 10px"}} offY="-3px" text="Finde Gesuche, die mindestens einen der folgenden Tänze enthalten (und das angegebene Level erfüllen)"/>
				</span>
				{props.show == 2 && <button className="btn" style={{fontSize:"35px", padding:"0px 15px"}}  onClick={e => {e.preventDefault(); toggle(1)}}>{folded[1] ? '+' : '-'}</button>}
			</h2>
			<div className="filler">
				<table style={{marginTop:"5px"}}><tbody>
					<tr><th>Tanz</th><th>min</th><th>max</th><th><button className="btn" style={{width:"30px", height:"30px", lineHeight:"0px", borderRadius:"10px"}} disabled={dances.length == danceData.length} onClick={newDance}>+</button></th></tr>
					{dances.map((dance, id) => {
						var danceInfo = danceData[dance[0]-1];
						var remaining = [danceInfo,...remainingDances];
						return (
							<tr key={id}>
								<td>
									<MySelect style={style} value={dance[0]} set={v => setDanceValue(id, v, 0)}>
										{createOptions(remaining.map(d=>d.name), remaining.map(d=>d.id))}
									</MySelect>
								</td>
								<td>
									<MySelect value={dance[1]} set={v => setDanceValue(id, v, 1)}>
										{createOptions(danceInfo.levels)}
									</MySelect>
								</td>
								<td>
									<MySelect value={dance[2]} set={v => setDanceValue(id, v, 2)}>
										{createOptions(danceInfo.levels)}
									</MySelect>
								</td>
								<td>
									{id>0 && <button onClick={e => deleteDance(id)}>&#10060;</button>}
								</td>
							</tr>
						)})}
				</tbody></table>
			</div>
		</div>
	)


	const sregions = search.regions ?? ["Deutschland"];
	const states = {};
	if(sregions.length == 0 || sregions[0] == "Deutschland") states.Deutschland = [];
	else {
		sregions.forEach(region => {
			if(stateNames.indexOf(region)  < 0) {
				var rg = regionInfo.find(r => r.region == region)
				if(rg == null) {
					debugger;
				}
				var state = rg.state;
				states[state] = [...states[state] ?? [], region];
			} else {
				states[region] = ["all"];
			}
		});
	}
	const selects = [];

	var snames = ["Deutschland", ...stateNames].filter(s => states[s] == undefined);
	var stateList = [...snames];
	var stateKeys = [...snames];
	var j = 0;

	for(var stt in states) {
		let state = stt;
		selects.push(<MySelect key={j++} className="stateSelector" value={state} set={v => setState(state, v)}>{createOptions([stt,...stateList], [stt,...stateKeys])}</MySelect>);
		if(selects.length==1) {
			stateList[0] = "";
			stateKeys[0] = "null";
		}
		if(state == "Deutschland") break;
		let selectedRegions = [...states[state]];
		let n = selectedRegions.length;
		var regionValues = stateInfo[state].filter(r => selectedRegions.indexOf(r) < 0);
		var regionKeys = [...regionValues];
		regionValues.splice(0,0,"", "alle Kreise");
		regionKeys.splice(0,0,"null", "all");

		selectedRegions.forEach((region, i) => {
			var vals = [...regionValues]
			var keys = [...regionKeys]
			if(i == 0) {
				regionValues.splice(1,1);
				regionKeys.splice(1,1);
				if(n == 1) {
					vals.splice(0,1);
					keys.splice(0,1);
				}
			}
			if(region != "all") {
				vals.splice(0,0,region)
				keys.splice(0,0,region)
			}
			selects.push(<MySelect key={j++} className="regionSelector" value={region} set={v => setRegion(state, i, v)}>{createOptions(vals, keys)}</MySelect>);
		});
		if(selectedRegions[0] != "all")
			selects.push(<MySelect key={j++} className="regionSelector" value="null" set={v => setRegion(state, n, v)}>{createOptions(regionValues, regionKeys)}</MySelect>);
	}
	if(states.Deutschland == undefined)
		selects.push(<MySelect key={j++} className="stateSelector" value="null" set={v => setState(null, v)}>{createOptions(stateList, stateKeys)}</MySelect>);

	function saveRegions(newStates) {
		var s = [];
		if(newStates.Deutschland == undefined) {
			for(var state in newStates) {
				var regions = newStates[state];
				if(regions[0] == "all") s.push(state);
				else s.push(...regions);
			}
		} else s = ["Deutschland"];
		dispatch(setSearch({...search,regions:s}));
	}

	function setState(oldState, val) {
		if(val == "deutschland") saveRegions({Deutschland:[]});
		else {
			if(val != "null" && states[val] != undefined) return;
			var newStates = {};
			if(oldState == null) newStates = {...states, [val]: ['all']}
			else {
				for(var state in states) {
					if(state == oldState) {
						if(val == "null") continue;
						newStates[val] = ["all"];
					} else newStates[state] = states[state]
				}
			}
			saveRegions(newStates);
		}
	}

	function setRegion(state, i, val) {
		var regions = states[state];
		if(val == "null") regions.splice(i,1);
		else {
			if(regions.indexOf(val) != -1) return;
			regions[i] = val;
			if(val == "all") regions.splice(1);
		}
		saveRegions(states)
	}

	const regionsView = (
		<div className={clazzes[2]} style={{width:'320px'}}>
			<h2><span>Gebiet</span> {props.show == 2 && <button className="btn" style={{fontSize:"35px", padding:"0px 15px"}} onClick={e => {e.preventDefault();toggle(2)}}>{folded[2] ? '+' : '-'}</button>}</h2>
			<div className="filler">
				{selects}
			</div>
		</div>
	)

	var styles = {padding:"5px"}
	if(props.show == 0) styles.display = "none";
	return (
		<div className="search" style={styles}>
			<div className="flexContainer stretch" style={{alignItems:"stretch !important", marginBottom:"5px"}}>
				{generalView}
				<div className="seperator" style={{backgroundColor:"unset", margin:0}}/>
				{dancesView}
				<div className="seperator" style={{backgroundColor:"unset", margin:0}}/>
				{regionsView}
			</div>
			{search.visible != 0 && props.show == 1 && <MyTextArea placeholder="Suchtext" style={{width:"calc(100% - 5px)", height:"50px", marginTop: "5px"}} value={search.searchtext} set={v => dispatch(setSearch({...search, searchtext:v}))}/>}
			{props.footer == 1 && <div id="searchDlgFooter" style={{width:"100%"}}></div>}
		</div>
	);
}
