import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {Link, useParams} from "react-router-dom";
import {query, MyInput} from '../utils/utils';
export default function Reset() {

	const {id, key} = useParams();
	const [err, setErr] = useState(null);
	const [success, setSuccess] = useState(false);
	const [pw, setPw] = useState("");
	const [pw2, setPw2] = useState("");

	function save() {
		query('confirmReset', {id:id, key:key, pw:pw}, () => setSuccess(true), err => {
			setErr("Link konnte nicht verifiziert werden");
			console.log(err);
		})
	}

	return (
		<div id="reset"  style={{marginTop:"15%"}}>
			<p style={err == null ? {display: 'none'} : {color:"red"}}>{err}</p>
			{success && <p>Passwort gespeichert.</p>}
			Neues Passwort
			<MyInput style={{width: "175px", margin: "10px"}} type="password" value={pw} set={setPw}/>
			Passwort wiederholen
			<MyInput style={{width: "175px", margin: "10px"}} type="password" value={pw2} set={setPw2}/>
			<button className="btn" disabled={pw.length == 0 || pw != pw2} style={{width: "175px", margin: "10px", textAlign:"center", marginTop:"20px"}} type="button" onClick={save}>speichern</button>
		</div>
	)
}
