import React from 'react';
import {danceData, regions, stateNames, states} from './data';
import Tooltip from '../utils/Tooltip';

export const MyInput = props => {
	const p = {...props};
	const set = props.set;
	delete p.set;
	if(p.original !== undefined) {
		var classList = (p.className ?? "").split(" ");
		if(p.original != (p.value ?? "")) {
			classList.push("changed");
		}
		p.className = classList.join(" ");
	}
	return <input {...p} onChange={e => set(e.target.type == "checkbox" ? (e.target.checked ? 1 : 0) : e.target.value, props.idx)}/>
}

export const MySelect = props => {
	const p = {...props};
	const set = props.set;
	delete p.set;
	delete p.children;
	if(p.original !== undefined) {
		var classList = (p.className ?? "").split(" ");
		if(p.original != p.value) {
			classList.push("changed");
		}
		p.className = classList.join(" ");
	}
	return <select {...p} onChange={e => set(e.target.value, props.idx)}>{props.children}</select>
}

export const MyTextArea = props => {
	const p = {...props};
	const set = props.set;
	delete p.set;
	if(p.original !== undefined) {
		var classList = (p.className ?? "").split(" ");
		if((p.original ?? "") != (p.value ?? "")) {
			classList.push("changed");
		}
		p.className = classList.join(" ");
	}
	return <textarea {...p} onChange={e => set(e.target.value, props.idx)}/>
}


export const MyTr = props => {
	return (
		<tr>
			<td>
				{props.lbl}
				{props.tooltip && <Tooltip dark={true} styles={{width:"15px", height:"15px", marginLeft:"5px"}} text={props.tooltip}/>}
			</td>
			<td>{props.children}</td>
		</tr>
	)
}

export const query = (f, d, callback, errorCB=null) => {
	var data = {...d}
	//dev
	var url = serverUrl + '/sql.php?f=' + f;
	for(var key in data) url += "&" + key + "=" + encodeURIComponent(data[key]);
	fetch(url)
		.then(response => response.json())
		.then(json => {
			if(json.success) callback(json.data);
			else {
				if(errorCB == null) console.log(json.data)
				else errorCB(json.data);
			}
		})
}

export const serverUrl = 'https://tanzpartner-wanted.de';

export const formatRange = (min, max, suffix="") => {
	var noMin = (min ?? "").length == 0;
	var noMax = (max ?? "").length == 0;
	if(noMin && noMax) return null;
	if(noMin) return "max. " + max + suffix;
	if(noMax) return "min. " + min + suffix;
	return min + " - " + max + suffix;
}

export const createOptions = (values, keys=null) => {
	return values.map((value, i) => {
		var key = keys == null ? i : keys[i];
		return <option key={key} value={key}>{value}</option>
	});

}

export const formatDate = (s, time=false) => {
	var d = new Date(s);
	var options = {year: '2-digit', month: '2-digit', day: '2-digit' };
	if(time) options = {...options, hour: '2-digit', minute:'2-digit'}
	return d.toLocaleString('de-DE', options);
}

/*export const getSearchQuery = (search, match) => {
	let where = [`s.typ=${search.typ}`];
	let having = [];
	if(search.minAge) having.push(`age >= ${search.minAge}`);
	if(search.maxAge) having.push(`age <= ${search.maxAge}`);
	if(search.minSize) where.push(`u.size >= ${search.minsize}`);
	if(search.maxSize) where.push(`u.size <= ${search.maxsize}`);
	if(search.genders.length < 3) where.push(`u.gender in ('${search.genders.join("','")}')`);
	if(search.typ == 0 && search.datum) where.push(`s.datum='${search.datum}'`);
	if(match) {
		where.push(`(s.minAge is NULL OR s.minAge <= ${match.user.age ?? 0})`);
		where.push(`(s.maxAge is NULL OR s.maxAge >= ${match.user.age ?? 999})`);
		where.push(`(s.minSize is NULL OR s.minSize <= ${match.user.size ?? 0})`);
		where.push(`(s.maxSize is NULL OR s.maxSize >= ${match.user.size ?? 999999})`);
		if(match.user.gender) where.push(`s.genders LIKE '%${match.user.gender}%'`);
		else where.push(`s.genders = "w/m/d"`);
	}
	var skills = [];
	var dances = [];
	search.dances.forEach(dance => {
		var cond = [];
		var maxLvl = danceData[dance[0]-1].levels.length-1;
		if(dance[1] > 0) cond.push(`level >= ${dance[1]}`);
		if(dance[2] < maxLvl) cond.push(`level >= ${dance[1]}`);
		if(cond.length > 0) cond.push(`dance = ${dance[0]}`);
		if(match) {
			var skill = Object.values(match.skills).find(s => s.dance == dance[0]);
			if(skill == undefined) {
				dances.push(`s.dances LIKE '%;${dance[0]},0,${maxLvl}%'`)
			} else {
				var or = [];
				var not = [];
				for(var i = 0; i <= skill.level; i++) {
					or.push(`s.dances LIKE '%;${dance[0]},${i},%'`)
					if(i < skill.level) not.push(`s.dances NOT LIKE '%;${dance[0]},%,${i};%' `)
				}
				var cond2 = `(${or.join(" OR ")})`;
				if(not.length > 0) cond2 = `(${cond2} AND ${not.join(" AND ")})`
				dances.push(cond2);

			}

		} else cond.push(`s.dances LIKE '%;${dance[0]}%'`);
		if(cond.length > 0) skills.push(`(${cond.join(" AND ")})`);
	});
	if(skills.length > 0) where.push(`(SELECT COUNT(*) FROM Skills WHERE userId=u.id AND (${skills.join(" OR ")}))`);
	if(dances.length > 0) where.push(`(${dances.join(" OR ")})`);


	if(search.regions[0] != 'Deutschland') {
		var regions = search.regions.join("','");
		var sub = `SELECT COUNT(*) FROM Regions r WHERE (s.regions LIKE CONCAT('%', r.name, '%') OR s.regions LIKE CONCAT('%', r.state, '%')) AND (r.name IN ('${regions}') OR r.state in ('${regions}'))`;
		where.push(`s.regions='Deutschland' OR (${sub}) > 0`)
	}



	return {
		where: where,
		having: having
	};
}*/

export const storeSearch = (search, onSucc, onErr) => {
	let data = {...search};
	data.genders = data.genders.join("/");
	data.regions = data.regions.join(";");
	data.dances = ";" + data.dances.map(d => d.join(",")).join(";") + ";";
	query('updateSearch', data, onSucc, onErr);
}

export const emptySearch = {
	typ: 0,
	dances: [[1,0,danceData[0].levels.length-1]],
	datum: null,
	genders: ['w', 'm', 'd'],
	minAge: null,
	maxAge: null,
	minSize: null,
	maxSize: null,
	notify: 1,
	visible: 1,
	notify: 1,
	regions: ['Deutschland'],
	searchtext: ''
}
