import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import ReCAPTCHA from "react-google-recaptcha";
import {MyInput, MySelect, MyTextArea, MyTr, query, formatRange, createOptions}  from '../utils/utils';
import {danceData, regions, stateNames, states} from '../utils/data';


function Register(props) {
	const columns = ["name","pw","pw2","mail","bday","gender","size","plz","recaptchaResponse","err","success"];
	const state = {};
	const setter = {};
	// eslint-disable-next-line react-hooks/rules-of-hooks
	columns.forEach( (k,i) => {[state[k],setter[k]] = useState(i < 8 ? "" : null)});


	const ref = React.createRef();

	function onCaptcha(val) {
		setter.recaptchaResponse(val);
	}

	function register() {
		if(state.name.length < 5 || state.name.length > 32) {
			setter.err("Der Benutzername muss 5-32 Zeichen lang sein.");
		} else if(state.pw.length < 5 || state.pw.length > 32) {
			setter.err("Das Passwort muss 5-32 Zeichen lang sein.");
		}	else if(state.pw != state.pw2) {
			setter.err("Passwörter stimmen nicht überein.");
			return;
		} else if(state.plz.length != 0 && state.plz.length != 5) {
			setter.err("Postleizahl muss aus 5 Ziffern bestehen");
			return;
		} else {
			const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if(!re.test(state.mail)) {
				setter.err("ungültige E-Mail Adresse");
				return;
			}
			query('register', state, result => {
				setter.success(true);
				ReactDOM.render(<p>Registrierung erfolgreich. Bitte Bestätigen Sie Ihre E-Mail Adresse.</p>, document.getElementById('register'))
			}, err => {
				setter.err(err);
				if(ref.current) ref.current.reset();
			})
		}
		if(ref.current) ref.current.reset();
	}


	return (
		<div id="register">
			<h2>Registrierung</h2>
			<p style={state.err === null ? {display:"none"} : {color:"red"}}>{state.err}</p>

			<div className="flexContainer" style={state.success && {display:"none"}}>
				<table>
					<tr><td colspan="2" style={{fontSize:"26px"}}>Pflichtdaten</td></tr>
					<MyTr lbl="Benutzername"><MyInput type="text" value={state.name} set={setter.name}/></MyTr>
					<MyTr lbl="Passwort"><MyInput type="password" value={state.pw} set={setter.pw}/></MyTr>
					<MyTr lbl="Passwort wiederholen"><MyInput type="password" value={state.pw2} set={setter.pw2}/></MyTr>
					<MyTr lbl="E-mail"><MyInput type="text" value={state.mail} set={setter.mail}/></MyTr>
				</table>
				<div class="seperator"></div>
				<table>
					<tr><td colspan="2" style={{fontSize:"26px"}}>Empfohlene Angaben</td></tr>
					<tr><td colspan="2" style={{maxWidth:"100px", textAlign:"justify;"}}>Diese Angaben werden benutzt, um nach passenden Gesuchen zu filtern und um dich bei neuen passenden Gesuchen zu benachrichtigen(sofern aktiviert)</td></tr>
					<MyTr lbl="Geburtsdatum"><MyInput type="date" value={state.bday} set={setter.bday}/></MyTr>
					<tr><td colspan="2">(nur das Alter ist für andere sichtbar)</td></tr>
					<MyTr lbl="Geschlecht">
							<MySelect value={state.gender} set={setter.gender}>
								<option value="null"></option>
								<option value="f">weiblich</option>
								<option value="m">männlich</option>
								<option value="d">andere</option>
							</MySelect>
					</MyTr>
					<MyTr lbl="Körpergröße in cm"><MyInput type="number" min="0" max="999" value={state.size} set={setter.size}/></MyTr>
					<MyTr lbl="Postleitzahl"><MyInput type="number" min="10000" max="99999" value={state.plz} set={setter.plz}/></MyTr>
				</table>
			</div>
			<ReCAPTCHA
			ref={ref}
			sitekey="6LecUrwaAAAAAPu5lPyZajoGkr3SKhyLDnNySlIw"
			onChange={onCaptcha}
			/>
			<button className="btn" style={{marginTop:"20px"}} type="button" onClick={register}>Registrieren</button>
		</div>
	)


}

export default Register;
