import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

function Test() {
	const dispatch = useDispatch();
  return (
    <div>
      <span></span>
    </div>
  );
}

export default Test
