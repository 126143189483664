import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import {getId, setDialog, setSearch, getSearch} from '../utils/Redux';
import {MyInput, MySelect, MyTextArea, MyTr, query, formatRange, createOptions, serverUrl, formatDate} from '../utils/utils';
import {danceData, regions, stateNames, states} from '../utils/data';
import { useSelector, useDispatch } from 'react-redux';
import SearchDlg from './SearchDlg';
import SearchResult from './SearchResult';
import store from '../utils/store';
import {MyTooltip} from '../utils/Tooltip';
import {Link} from "react-router-dom";
import ReactTooltip from "react-tooltip";

export default function Messages(props) {

	let {id, sid} = useParams()

	const [contacts, setContacts] = useState([]);
	const [contact, setContact] = useState(-1);
	const [messages, setMessages] = useState(null);
	const [message, setMessage] = useState("");
	const [showContacts, setShowContacts] = useState(true);

	useEffect( () => {
		query('contacts', {id:id ?? 0}, data => {
			setContacts(data);
			if(id) load(id, 0, data);

		})
	},[])

	function load(cid, off=0, contactList = null) {
		setContact(0);
		query('messages', {cid:cid, offset:off}, data => {
			var contacts2 = [...contactList ?? contacts];
			var idx = contacts2.findIndex(c => c.id==cid);
			contacts2[idx] = {...contacts2[idx], seen:1}
			//setContacts(contacts2);
			var msgs = (contact == cid) ? messages.filter(x => typeof(x)=='object') : [];
			data.forEach(msg => {
				if(msgs.find(m => m.id==msg.id)) return;
				msgs.push(msg);
			});
			msgs.sort((a,b) => b.id-a.id);

			setMessages(msgs);
			setContact(cid);
		})
	}


	function send(cid, text) {
		let msg = {content:text, recepient:cid};
		setMessage("");
		query('send', msg, mid => {
			msg.sent=1;
			msg.seen=0;
			msg.id=mid;
			load(contact);
			var contacts2 = [...contacts];
			var idx = contacts2.findIndex(c => c.id==cid);
			contacts2[idx] = {...contacts2[idx], sent:1, content:text}
			setContacts(contacts2);
		})
	}

	function toggleContacts() {
		if(showContacts) {
			setShowContacts(false);
		} else {

			setShowContacts(true);
		}
	}

	var title = "";
	if(contact === 0) title = 'loading';
	else if(contact > 0) {
		var c = contacts.find(x => x.id==contact)
		title = c.name ? <Link to={'user/'+contact}>{c.name}</Link> : <i>Gelöschtes Profil</i>
	}


	return (
		<div id="messagesMain" style={{display:"flex", flexDirection:"row", height:"100%"}}>
			<div id="contacts" className="container" style={showContacts ? {minWidth:"350px", maxWidth:"350px"} : {minWidth:'unset'}}>
				<h2>
					{showContacts && <span>Meine Nachrichten</span> }
					<button className="btn" style={{width:"50px", padding:"5px 16px"}} onClick={e => toggleContacts()}><img style={{width:"18px"}} src={showContacts ? 'img/leftArrow.png' : 'img/rightArrow.png'}/></button>
				</h2>
				<div className='filler' style={{display:'flex', flexDirection: 'column', justifyContent:'start', padding:'0px'}}>
					{showContacts && contacts.map((c,row) => {
						var text = c.content.replaceAll("\r","").replaceAll("\n"," ");
						var clazz = c.id==contact ? "selected" : c.sent==0 && c.seen==0 ? "unseen" : "";
						return (
							<div className={clazz} key={c.id} onClick={e => load(c.id)}>
								<span>{c.name ?? <i>Gelöschtes Profil</i>}</span><br/><xmp>{text.length > 40 ? text.substr(0,40) + "..." : text}</xmp>
							</div>
						)
					})}
				</div>
			</div>

			<div style={contact == -1 ? {display:'none'} : {}} id="contactContainer" className="container">

				<h2><span>{title}</span></h2>
				<div id="messages" className="filler">
					{contact != 0 && messages && (
						<div style={{display:'flex', marginBottom:'20px'}}>
							<MyTextArea style={{resize:"none", height:"100px", flexGrow:'1', borderRadius:'10px'}} value={message} set={setMessage}/>
							<button className="btn" style={{margin:'20px'}} disabled={message.length==0} onClick={e => send(contact, message)}>senden</button>
						</div>
					)}
					{contact != 0 && messages && (
							messages.map(m => {
								if(typeof(m) != 'object') {
									return <Link to="" key="more" onClick={e => {e.preventDefault(); load(contact, parseInt(m)+1)}}>mehr anzeigen</Link>
								}
								return (
								<span key={m.id} className={m.sent == 1 ? "sent" : "received"}>
									<xmp data-for={"message"+m.id} data-tip={formatDate(m.created, true)} data-iscapture="true">{m.content}</xmp>
									<MyTooltip id={"message"+m.id}/>
								</span>
							)})
					)}
				</div>
			</div>
		</div>
	)
}
