import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from "react-tooltip";

export default function Tooltip(props) {
	var place = props.place ?? "top";
	var type = props.type ?? "dark";
	var effect = props.effect ?? "solid";
	let style = {display:"inline-block", width:"25px", height:"25px", ...props.styles}
	return (
		<div style={style}>
			<img
				data-for="main"
				data-tip={props.text}
				data-iscapture="true"
				src={props.dark ? "img/infoDark.png" : "img/info.png"}
				style={{height:"100%", marginBottom:props.offY ?? "0px"}}>

			</img>
			<MyTooltip
				id="main"
			/>
		</div>
	)
}

export const MyTooltip = props => {
	return (
		<ReactTooltip
			id={props.id}
			place="top"
			effect="solid"
			type="dark"
			multiline={true}
		/>
	)
}
