import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import {getId, setDialog, setSearch, getSearch} from '../utils/Redux';
import {MyInput, MySelect, MyTextArea, MyTr, query, formatRange, createOptions, serverUrl, storeSearch, emptySearch, formatDate} from '../utils/utils';
import {danceData, regions, stateNames, states} from '../utils/data';
import { useSelector, useDispatch } from 'react-redux';
import SearchDlg from './SearchDlg';
import store from '../utils/store'
import {Link} from "react-router-dom";
import {MyTooltip} from '../utils/Tooltip';

export default function Profile(props) {
	const dispatch = useDispatch();
	const [state, setState] = useState({user: null, skills: null, searches:null});
	const [sig, setSig] = useState(null);
	const update = () => {
		query('profile', [], data => {
			setState(data);
			setSig(data.user.signature);
		})
	}
	useEffect( update,[])

	function save() {
		let text = state.user.signature;
		query('updateSignature', {text: text }, () => setSig(text));
	}

	function deleteProfile() {
			dispatch(setDialog(2));
			ReactDOM.render(<DelProfile close={e => dispatch(setDialog(0))} history={props.history}/>, document.getElementById("dlgContent"))
	}

	return state.user && (
		<div id="profile">
			<br/>
			<button className="btn" style={{display:"flex", alignItems:"center", alignSelf:"end", padding:"5px 15px", fontSize:"16px", width:"370px"}} onClick={e => props.history.push(`/user/${state.user.id}`)}>
				<img src="img/profile.png" style={{height:"30px", marginRight:"20px"}}/>
				<b>So sieht mein Profil für Andere aus.</b>
			</button>
			<div className="flexContainer" style={{width:"1200px", justifyContent:"space-between"}}>
				<div style={{display:"flex", flexDirection:"column", justifyContent:"space-between", maxWidth:"300px"}}>
					<UserImage update={update} image={state.user.img}/>
					<div className="container">
						<h2><span>Profiltext</span> <button className="btn" onClick={save}><img src="img/check.png" style={{height:"90%", marginRight:"-5px"}}/> speichern</button></h2>
						<div className="filler">
							<MyTextArea
								placeholder="Erzähl ein bisschen was über dich"
								style={{margin:"10px 0px", borderRadius:"5px", height:"100px", width:"calc(100% - 10px)"}}
								original={sig} value={state.user.signature}
								set={v => setState({...state, user:{...state.user, signature:v}})}
							/>
						</div>
					</div>
				</div>
				{state.user && <UserSettings user={state.user}/>}
				{state.skills && <Skills skills={state.skills}/>}
			</div>
			{state.searches && <Searches history={props.history} searches={state.searches}/>}
			<button className="btn"  style={{fontSize:"14px", marginTop:"20px"}} onClick={e => deleteProfile()}>Profil löschen</button>
		</div>
	)
}

function DelProfile(props) {
	const [pw, setPW] = useState("");
	const [err, setErr] = useState(null);
	function confirm() {
		query('deleteProfile', {pw:pw}, () => {
			props.close();
			props.history.push('/logout');
		}, err => {
			setErr(err.code == 1 ? err.msg : "Unerwarteter Fehler")
		})
	}

	return (
		<div style={{display:"flex", flexDirection:"column", alignItems:"center", padding:"10px"}}>
			<div style={{textAlign:"center"}}>
				Möchtest du dein Profil wirklich löschen?<br/>
				Hierbei werden alle deine Daten gelöscht.<br/>
				Dies kann nicht rückgängig gemacht werden.<br/>
				Zur Bestätigung gib bitte dein Passwort ein:
			</div><br/>
			{err && <div style={{color:"red"}}>{err}</div>}
			<MyInput type="password" value={pw} set={setPW}/><br/>
			<div>
				<button id="uploadbtn" className="btn" type="submit" onClick={props.close}>abbrechen</button>
				&nbsp;&nbsp;&nbsp;
				<button id="deletebtn" className="btn" onClick={confirm}>Profil löschen</button>
			</div>
		</div>
	)
}

function UserImage(props) {
	const dispatch = useDispatch();

	function showDlg() {
		dispatch(setDialog(2));
		ReactDOM.render(<ImageUpload update={props.update} close={e => dispatch(setDialog(0))}/>, document.getElementById("dlgContent"))
	}

	var img = props.image ?? "";
	// <div style={{width:"300px", height:"300px", cursor:"pointer"}} onClick={showDlg}>
	return (
		<div style={{maxHeight:"200px"}} >
			{ img.startsWith('user')
				? <img className="profilePic" src={"img/user/" + img} style={{cursor:"pointer"}} onClick={showDlg}/>
				: <ImageUpload update={props.update}/>
			}
		</div>
	)
}

function ImageUpload(props) {
	const [file, setFile] = useState(null);

	async function handleSubmit(event) {
		event.preventDefault();
		await uploadFile(file);
		if(props.close) props.close();
		props.update();
	}


	async function uploadFile(file) {
		if(file == null)	{
			return;
		}
		const formData = new FormData();

		formData.append('avatar',file)

		return await axios.post(serverUrl + '/sql.php', formData,{
				headers: {
						'content-type': 'multipart/form-data'
				}
		});
	}

	function deleteImg() {
		query('deleteImg', {}, succ => {props.close()})
	}



	return (
		<form onSubmit={handleSubmit} style={{textAlign:"center"}}>
			neues Profilbild hochladen(max 1MB):
			<br/>
			<input type="file" style={{margin:"10px"}} onChange={e => {
				setFile(e.target.files[0]);
				document.getElementById('uploadbtn').style.removeProperty('display');
				document.getElementById('deletebtn').style.display = 'none';
			}} />
			<br/>
			<button id="uploadbtn" className="btn" type="submit" disabled={file == null} style={file == null && props.close ? {display:'none'} : {}}>Hochladen</button>
			<button id="deletebtn" className="btn" onClick={deleteImg} style={file || props.close == undefined ? {display:'none'} : {}}>Bild löschen</button>
			&nbsp;
			{props.close && <button className="btn" onClick={props.close}>Abbrechen</button>}
		</form>
	)
}

function UserSettings(props) {
	const [user, setUser] = useState(props.user);
	const [originalUser, setOriginal] = useState(props.user);
	const [err, setErr] = useState(null);


	function setUserValue(val, idx) {
		var v = (val.length == 0) ? null : val;
		setUser({...user, [idx]:v});
	}

	const dispatch = useDispatch();

	const save = () => {
		query('updateUser', user, data =>setOriginal(user), err => { // TODO:
			setErr(err);
		})
	}


	return (
		<div id="userSettings" className="container">
			<h2><span>Persönliche Infos</span> <button className="btn" onClick={save}><img src="img/check.png" style={{height:"90%", marginRight:"-5PX"}}/> speichern</button></h2>
			<div className="filler">
				{err && <p style={{color:"red", margin:"0px"}}>{err}</p> }
				<table><tbody>
					<MyTr lbl="Neues Passwort:"><MyInput type="password" original="" value={user.pw} set={setUserValue} idx="pw"/></MyTr>
					<MyTr lbl="Passwort wiederholen:"><MyInput type="password" original="" value={user.pw2} set={setUserValue} idx="pw2"/></MyTr>
					<tr><td>E-Mail Adresse:<br/><span style={{fontSize:"12px"}}>(wird nicht angezeigt)</span></td><td><MyInput type="text" value={user.mail} original={originalUser.mail} set={setUserValue} idx="mail"/></td></tr>
					<MyTr lbl="E-Mail bei neuer Nachricht"><MyInput type="checkbox" checked={user.notifyMsg == 1} set={setUserValue} idx="notifyMsg"/></MyTr>
					<tr><td colSpan="2"><div className="dashedLine"></div></td></tr>
					<MyTr lbl="Geschlecht:">
						<MySelect type="" value={user.gender ?? "null"} original={originalUser.gender} set={setUserValue} idx="gender">
							<option value="null"></option>
							<option value="f">weiblich</option>
							<option value="m">männlich</option>
							<option value="d">andere</option>
						</MySelect>
					</MyTr>
					<MyTr lbl="Körpergröße."><MyInput type="number" value={user.size ?? ""} original={originalUser.size ?? ""} set={setUserValue} idx="size"/></MyTr>
					<MyTr lbl="Postleitzahl."><MyInput type="number" value={user.plz ?? ""} original={originalUser.plz ?? ""} set={setUserValue} idx="plz"/></MyTr>
					<tr><td>Geburtstag:<br/><span style={{fontSize:"12px"}}>(nur das Alter wird angezeigt)</span></td><td><MyInput type="date" value={user.bday ?? ""} original={originalUser.bday ?? ""} set={setUserValue} idx="bday"/></td></tr>
				</tbody></table>
			</div>
		</div>
	)
}

function Skills(props) {
	const [skills, setSkills] = useState(props.skills);

	const skillsArr = Object.values(skills);
	skillsArr.sort((x,y) => y.level-x.level);

	const remaining = Object.values(danceData).filter(d => skillsArr.find(s => s.dance==d.id)==null).map(d => {return {id: -1, dance:d.id, level:0, name:d.name}});
	//const [state, setState] = useState(false);

	function skillRow(skill) {
		function set(v) {
			const copy = {...skill, level:v};
			query('updateSkill', copy, (data)=> {
				var cskills = {...skills};
				cskills[data.id] = {...copy, id:data.id}
				setSkills(cskills);
			});
		}
		return (
			<MyTr key={skill.dance} lbl={danceData[skill.dance-1].name}>
				<MySelect value={skill.level} set={set}>
					{danceData[skill.dance-1].levels.map((lvl,i) => (
						<option key={i} value={i}>{lvl}</option>
					))}
				</MySelect>
			</MyTr>
		)
	}

	return (
		<div id="skills" className="container" style={{width:"370px"}}>
			<h2><span>Kenntnisse</span></h2>
			<div className="filler">
				<table><tbody>
					{skillsArr.map(skillRow)}
					{remaining.map(skillRow)}
				</tbody></table>
			</div>
			<p>Hier fehlt etwas? Bitte schreiben Sie eine E-Mail an <a href="mailto:tanzpartnerwanted@googlemail.com">tanzpartnerwanted@googlemail.com</a></p>
		</div>
	)
}
// <tr><td colSpan="2" style={{textAlign: "center"}}><button className="btn" onClick={() => setState(!state)}>alle zeigen</button></td></tr>


function Searches(props) {
	const [searches, setSearches] = useState(props.searches);
	const colors = ["#def", "#ace"];
	const types = ["Event", "Kurs/Training"];
	const dispatch = useDispatch();

	function openSearch(search) {
		dispatch(setSearch(search));
		dispatch(setDialog(1));
		ReactDOM.render((
			<div style={{width:"100%", textAlign:"center"}}>
				<button className="btn" style={{margin:"10px"}} onClick={saveSearch}>speichern</button>
				<button className="btn" style={{margin:"10px"}} onClick={e => dispatch(setDialog(0))}>abbrechen</button>
			</div>
		), document.getElementById("searchDlgFooter"));
	}

	const currentSearch = useSelector(getSearch);
	function saveSearch() {
		let search = store.getState().search;
		storeSearch(search, id => {
			var searchesCopy = [...searches];
			if(search.id == undefined) searchesCopy.push({...search, id:id});
			else {
				var i = searchesCopy.findIndex(s => s.id==search.id);
				searchesCopy[i] = search;
			}
			setSearches(searchesCopy);
			dispatch(setDialog(0));
		}, err => { // TODO
			console.log("ERROR", err);
		})
	}

	function newSearch() {
		openSearch(emptySearch);
	}

	function startSearch(search) {
		if(document.getElementsByClassName('changed').length > 0) {
			ReactDOM.render((
				<div style={{padding:"10px", textAlign:"center"}}>
					Sie haben nicht gespeicherte Änderungen.<br/>
					Möchten Sie wirklich zur Hauptsuche gehen und die Änderungen verwerfen?<br/>
					<button className="btn" style={{margin:"10px"}} onClick={e => {
						dispatch(setSearch(search));
						dispatch(setDialog(0));
						props.history.push(`/search/${search.id}`);
					}}>zur Hauptsuche</button>
					<button className="btn" style={{margin:"10px"}} onClick={e => dispatch(setDialog(0))}>abbrechen</button>
				</div>), document.getElementById("dlgContent"));
				dispatch(setDialog(2));
		} else {
			dispatch(setSearch(search));
			props.history.push(`/search/${search.id}`);
		}
	}

	function deleteSearch(search) {
		ReactDOM.render((
			<div style={{padding:"10px", textAlign:"center"}}>
				Wollen Sie diese Suche wirklich löschen?<br/>(kann nicht rückgängig gemacht werden)<br/>
				<button className="btn" style={{margin:"10px"}} onClick={e => confirmDelete(search)}>löschen</button>
				<button className="btn" style={{margin:"10px"}} onClick={e => dispatch(setDialog(0))}>abbrechen</button>
			</div>), document.getElementById("dlgContent"));
			dispatch(setDialog(2));
	}

	function confirmDelete(search) {
		query("updateSearch", {id:search.id, delete:1}, response => {
			var i = searches.findIndex(s => s.id==search.id);
			var copy = [...searches];
			copy.splice(i,1);
			dispatch(setDialog(0));
			setSearches(copy);
		})
	}

	return (
		<div id="searches" className="container" style={{marginTop: "50px", width:"1200px", maxWidth:"90vw"}}>
			<h2>
				<span>Meine Suchen</span>
				{
					searches.length > 9
					? <button data-for="btn" data-tip="Du kannst maximal 10 Suchen speichern" className="btn disabled">Neue Suche</button>
					: <button className="btn" onClick={newSearch}>Neue Suche</button>
				}
			</h2>
			<div className="filler">
				<table><tbody>
					<tr>
						<th>Tänze</th>
						<th>Art</th>
						<th>Alter</th>
						<th>Größe</th>
						<th>Gebiet(e)</th>
						<th>Geschlecht</th>
						<th>Datum</th>
						<th>sichtbar</th>
						<th>Benachrichtigung</th>
						<th colSpan="3">

						</th>
					</tr>
					{searches.map((s,i) => (
						<tr key={i} style={{backgroundColor: colors[i%2]}}>
							<td>{s.dances.length == 1 ? danceData[s.dances[0][0]-1].name : s.dances.length}</td>
							<td>{types[s.typ]}</td>
							<td>{formatRange(s.minAge, s.maxAge)}</td>
							<td>{formatRange(s.minSize, s.maxSize, "cm")}</td>
							<td>{s.regions.length == 1 ? s.regions[0] : s.regions.length}</td>
							<td>{s.genders.join("/")}</td>
							<td>{s.datum && formatDate(s.datum)}</td>
							<td>{s.visible ? 'Ja' : 'Nein'}</td>
							<td>{s.notifyMsg ? 'Ja' : 'Nein'}</td>
							<td><button className="btn" data-for="btn" data-tip="Suche bearbeiten" data-iscapture="true" onClick={e => openSearch(s)}><img src="img/edit.png" style={{width:"50%"}}/></button></td>
							<td><button className="btn" data-for="btn" data-tip="mit diesen Einstellungen zur Hauptsuche" data-iscapture="true" onClick={e => startSearch(s)}><img src="img/search.png" style={{width:"70%"}}/></button></td>
							<td><button className="btn" data-for="btn" data-tip="Suche löschen" data-iscapture="true" onClick={e => deleteSearch(s)}><img src="img/bin.png" style={{width:"50%"}}/></button></td>
						</tr>
					))}
				</tbody></table>
				<MyTooltip id="btn" />
			</div>
		</div>
	)
}
