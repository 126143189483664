import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {Link, useParams} from "react-router-dom";
import {query} from '../utils/utils';

export default function Confirm() {

	const {id, key} = useParams();
	const [err, setErr] = useState(null);
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		query('confirm', {id:id, key:key}, () => setSuccess(true), err => {
			setErr("Link konnte nicht verifiziert werden");
			console.log(err);
		})
	},[])

	return (
		<div id="reset"  style={{marginTop:"15%"}}>
			<p style={err == null ? {display: 'none'} : {color:"red"}}>{err}</p>
			{success && <p>E-Mail Adresse bestätigt.</p>}
		</div>
	)
}
